import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
	<path d="M5117 9778 c3 -112 7 -205 9 -207 1 -1 59 0 128 4 l126 7 0 40 0 40
-76 -3 c-58 -3 -77 0 -84 11 -5 8 -10 81 -10 163 l0 147 -49 0 -49 0 5 -202z"/>
<path d="M5655 9960 c-36 -23 -65 -74 -65 -112 0 -42 46 -85 125 -117 59 -23
70 -32 73 -54 2 -17 -3 -29 -17 -36 -38 -21 -121 11 -121 47 0 12 -9 13 -42 8
-23 -4 -45 -9 -47 -12 -3 -3 3 -22 14 -41 30 -55 94 -86 170 -81 164 10 201
181 53 242 -104 43 -108 46 -108 66 0 30 41 46 80 30 16 -7 30 -15 30 -19 0
-3 4 -12 9 -20 7 -11 15 -11 45 0 42 15 45 27 12 66 -44 52 -156 70 -211 33z"/>
<path d="M4843 9951 c-24 -4 -45 -10 -49 -13 -7 -8 -77 -408 -72 -413 2 -3 20
-2 41 2 34 5 37 8 43 48 l6 43 61 10 c34 5 65 8 70 5 4 -2 14 -20 22 -40 15
-35 16 -36 62 -29 25 3 48 8 50 9 5 6 -176 382 -185 383 -4 1 -26 -2 -49 -5z
m40 -177 c15 -30 25 -59 22 -65 -4 -5 -22 -9 -42 -9 l-36 0 7 46 c3 26 6 55 6
65 0 33 15 20 43 -37z"/>
<path d="M5996 9918 c-16 -98 -46 -329 -46 -350 0 -24 4 -25 130 -42 72 -10
137 -15 145 -12 9 3 15 18 15 38 l0 34 -92 12 c-51 7 -93 14 -95 16 -2 2 -1
22 3 46 7 40 8 42 38 36 17 -3 52 -8 79 -12 l47 -6 0 40 0 40 -70 7 -70 7 0
44 c0 24 3 44 8 44 4 0 47 -6 95 -14 48 -7 89 -10 91 -7 2 3 7 21 11 39 5 23
3 32 -7 32 -13 0 -187 25 -245 35 -30 5 -32 4 -37 -27z"/>
<path d="M4377 9880 c-48 -10 -89 -19 -91 -21 -3 -4 72 -391 77 -395 1 -1 20
1 42 5 45 7 44 6 30 112 l-8 57 34 7 c18 4 35 4 36 -1 2 -5 22 -38 44 -73 l41
-63 46 6 c25 3 47 8 50 11 2 2 -18 38 -46 80 l-51 76 25 36 c37 55 29 111 -22
158 -33 30 -78 31 -207 5z m141 -72 c19 -19 15 -66 -7 -77 -10 -6 -37 -13 -59
-17 -43 -7 -43 -7 -57 63 -5 25 -3 32 12 36 37 9 99 7 111 -5z"/>
<path d="M6375 9868 c-21 -69 -83 -374 -77 -380 4 -4 26 -9 47 -11 l39 -2 15
73 c15 75 20 79 65 62 17 -6 26 -17 26 -31 0 -12 5 -50 11 -85 l11 -62 45 -7
c25 -3 46 -5 48 -3 3 3 -6 70 -20 146 -6 36 -5 42 9 42 10 0 31 13 47 29 24
25 29 38 29 78 0 84 -32 108 -188 143 -108 24 -102 23 -107 8z m172 -99 c30
-11 38 -45 18 -75 -15 -24 -20 -25 -68 -19 -29 4 -55 9 -58 11 -3 2 -2 26 4
54 l10 50 36 -6 c20 -4 46 -10 58 -15z"/>
<path d="M4090 9804 c-41 -14 -96 -29 -122 -35 -26 -6 -50 -14 -52 -18 -5 -7
99 -379 107 -388 2 -2 33 5 68 16 35 11 97 29 137 41 70 20 72 22 66 48 -10
43 -18 45 -86 27 -123 -33 -121 -34 -133 23 -5 23 -3 33 7 36 7 3 39 12 71 21
58 16 58 16 52 48 -4 18 -9 34 -10 35 -2 2 -35 -4 -74 -14 -39 -10 -73 -16
-76 -12 -3 3 -8 21 -11 40 l-6 34 90 27 91 27 -10 32 c-5 18 -9 34 -9 35 0 7
-30 -1 -100 -23z"/>
<path d="M6766 9758 c-3 -18 -7 -113 -8 -212 l-3 -179 45 -13 c25 -8 46 -14
47 -14 1 0 21 28 46 63 24 34 79 111 121 170 43 59 77 109 75 111 -2 1 -16 7
-31 13 -34 15 -39 11 -127 -114 -71 -102 -96 -125 -87 -80 3 12 8 76 11 143
l7 120 -40 12 c-52 16 -50 17 -56 -20z"/>
<path d="M3758 9698 c-16 -5 -28 -13 -28 -18 0 -5 18 -51 39 -104 22 -53 35
-96 30 -96 -5 0 -52 34 -105 76 l-95 75 -49 -16 -49 -16 18 -47 c78 -200 135
-332 143 -332 7 0 45 17 55 24 1 1 -18 53 -42 116 -25 63 -44 117 -43 121 2 4
48 -29 103 -73 124 -99 135 -105 175 -88 16 7 30 16 30 20 0 4 -14 41 -31 81
-16 41 -48 119 -70 174 -22 55 -44 103 -47 106 -4 4 -19 2 -34 -3z"/>
<path d="M7141 9638 c-13 -33 -84 -211 -115 -287 -14 -36 -26 -66 -26 -67 0
-4 82 -35 85 -32 7 9 135 327 140 350 6 24 3 29 -30 42 -47 20 -44 20 -54 -6z"/>
<path d="M3278 9497 c-64 -34 -119 -65 -123 -68 -3 -4 5 -26 18 -50 13 -24 55
-102 92 -174 37 -71 70 -132 72 -135 2 -2 12 0 21 6 9 6 62 34 117 64 55 29
104 56 108 60 8 7 -18 70 -29 70 -3 0 -38 -18 -78 -40 -40 -22 -78 -40 -84
-40 -11 0 -42 50 -42 67 0 5 27 23 60 41 33 19 60 38 60 43 0 9 -17 48 -26 57
-1 2 -32 -12 -67 -32 l-65 -37 -22 37 c-26 42 -30 37 75 91 39 19 71 36 73 38
4 3 -32 65 -38 64 -3 0 -58 -28 -122 -62z"/>
<path d="M7345 9527 c-91 -42 -159 -182 -133 -275 21 -77 123 -143 200 -128
32 6 108 59 108 75 0 4 -18 16 -39 26 -39 17 -40 17 -67 -4 -34 -26 -54 -27
-88 0 -35 27 -34 65 5 145 36 77 73 103 120 84 32 -14 45 -45 35 -84 -5 -21 0
-26 36 -40 50 -21 58 -13 58 55 0 58 -31 107 -84 133 -50 24 -113 30 -151 13z"/>
<path d="M7703 9378 c-6 -7 -50 -83 -98 -168 -48 -85 -90 -158 -92 -163 -2 -4
4 -10 14 -14 10 -4 63 -33 118 -66 54 -32 103 -59 107 -60 5 -1 17 12 29 30
l20 32 -48 27 c-97 55 -113 66 -113 76 0 14 33 68 42 68 4 0 33 -16 65 -35
l58 -35 17 23 c27 34 22 43 -39 78 -32 18 -60 34 -62 36 -6 4 32 73 41 73 4 0
42 -21 84 -47 l77 -46 18 24 c10 13 19 29 19 35 0 9 -226 144 -242 144 -3 0
-10 -6 -15 -12z"/>
<path d="M2932 9319 c-56 -28 -85 -70 -90 -133 -12 -147 123 -288 243 -255 41
12 105 69 105 95 0 16 16 19 25 4 9 -14 35 -12 41 3 4 12 -72 157 -95 180 -10
10 -141 -62 -141 -78 0 -5 9 -21 19 -34 17 -23 19 -23 44 -6 31 20 47 13 47
-20 0 -31 -37 -65 -71 -65 -78 0 -166 172 -114 224 25 24 54 29 80 12 32 -21
40 -20 75 6 29 21 29 22 12 41 -22 25 -73 47 -109 47 -15 0 -47 -10 -71 -21z"/>
<path d="M5091 9209 c-41 -5 -79 -13 -85 -19 -5 -5 -18 -10 -28 -10 -24 0
-144 -54 -163 -74 -35 -35 -6 -35 31 -1 21 20 35 25 58 20 22 -3 25 -3 12 3
-18 8 -16 10 13 20 29 10 32 10 21 -3 -10 -12 -6 -13 23 -8 21 3 37 1 42 -7 5
-8 -1 -11 -21 -9 -32 3 -111 -26 -117 -44 -2 -7 -2 -1 0 13 4 24 4 24 -16 6
-11 -10 -17 -24 -14 -32 3 -8 0 -14 -6 -14 -6 0 -11 5 -11 10 0 6 -7 10 -15
10 -8 0 -15 -4 -15 -10 0 -5 3 -10 8 -10 4 0 13 -18 20 -41 12 -39 49 -100 65
-107 4 -2 7 -9 7 -16 0 -14 61 -96 71 -96 4 0 14 6 21 14 40 40 95 26 74 -19
-12 -27 -42 -46 -33 -21 4 10 1 13 -9 9 -7 -3 -14 -1 -14 5 0 6 6 13 13 15 8
4 7 6 -3 6 -8 0 -21 -3 -29 -8 -24 -15 51 -85 81 -76 15 4 19 3 10 -3 -16 -11
-8 -18 62 -57 57 -32 147 -59 246 -74 76 -12 180 -1 230 24 14 7 43 16 64 20
27 5 40 14 46 31 10 26 26 31 33 12 3 -10 13 -8 41 6 37 20 46 47 27 84 -6 9
-22 44 -38 77 -15 33 -31 65 -36 70 -4 6 -14 19 -22 30 -14 19 -14 19 -15 -7
0 -15 8 -31 18 -37 13 -8 14 -10 2 -11 -8 0 -21 9 -28 20 -7 11 -17 20 -23 20
-6 0 -9 -9 -6 -20 4 -15 0 -20 -14 -20 -10 0 -19 5 -19 10 0 6 5 10 11 10 7 0
9 12 5 35 -7 35 1 44 21 24 6 -6 16 -8 22 -4 17 10 -22 59 -83 106 -28 21 -56
43 -61 48 -13 10 -120 65 -155 80 -70 29 -181 36 -319 20z m186 -5 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-197 -35 c0 -10 -2 -19 -4 -19 -2
0 -11 -3 -20 -6 -11 -4 -16 -1 -16 10 0 9 -4 16 -10 16 -5 0 -10 4 -10 8 0 5
14 9 30 10 23 0 30 -4 30 -19z m281 4 c1 -17 2 -17 6 0 5 19 18 23 28 8 3 -5
0 -13 -6 -17 -7 -4 7 -15 36 -26 52 -20 75 -19 25 2 -16 7 -30 17 -30 22 0 5
3 8 8 6 114 -54 142 -78 106 -92 -11 -4 -14 -1 -10 12 5 16 2 18 -25 14 -17
-2 -33 -11 -36 -18 -7 -19 -23 -18 -23 1 0 9 -7 18 -15 21 -8 4 -12 10 -9 15
3 5 -1 9 -10 9 -8 0 -22 3 -32 7 -13 5 -15 3 -9 -8 5 -8 0 -6 -12 4 -30 26
-36 57 -12 57 11 0 19 -7 20 -17z m-336 -13 c4 -6 -5 -10 -19 -10 -14 0 -26 5
-26 10 0 6 9 10 19 10 11 0 23 -4 26 -10z m293 -63 c2 -12 -3 -17 -17 -17 -12
0 -21 6 -21 13 0 31 32 34 38 4z m-358 -7 c0 -5 -4 -10 -10 -10 -5 0 -10 5
-10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m150 0 c0 -5 -5 -10 -11 -10 -5 0 -7
5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m488 -26 c12 -8 22 -25 22 -38 0 -12
5 -27 12 -34 6 -6 9 -14 6 -17 -11 -11 -36 25 -31 44 7 26 -29 46 -56 31 -31
-16 -35 -12 -10 10 29 24 29 24 57 4z m-198 -14 c0 -5 -13 -10 -30 -10 -16 0
-30 5 -30 10 0 6 14 10 30 10 17 0 30 -4 30 -10z m-526 -42 c-2 -25 0 -38 5
-30 6 10 10 10 20 -1 9 -12 7 -16 -9 -20 -23 -6 -36 13 -26 39 8 20 -4 41 -14
24 -5 -8 -9 -7 -13 5 -4 9 -3 14 1 11 4 -2 14 -1 22 5 8 5 16 9 16 9 1 0 0
-19 -2 -42z m81 22 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2
0 7 -4 10 -10z m705 -20 c15 -15 16 -20 5 -20 -8 0 -19 9 -25 20 -6 11 -8 20
-5 20 3 0 14 -9 25 -20z m-40 -76 c0 -13 -4 -24 -10 -24 -5 0 -10 14 -10 31 0
17 4 28 10 24 6 -3 10 -17 10 -31z m-664 11 c11 -27 -1 -33 -19 -9 -16 21 -16
24 -2 24 9 0 18 -7 21 -15z m-37 -17 c5 -13 12 -32 16 -43 4 -14 8 -16 14 -7
5 8 8 1 8 -17 0 -39 -14 -30 -37 25 -11 24 -24 44 -30 44 -5 0 -10 5 -10 10 0
19 31 10 39 -12z m895 -190 c4 -37 -8 -58 -33 -58 -13 1 -13 3 4 17 l20 15
-20 -4 c-19 -4 -20 1 -22 75 l-2 79 25 -45 c13 -26 26 -61 28 -79z m-599 -29
c-4 -6 -10 -8 -13 -5 -3 3 -11 1 -18 -5 -8 -6 -22 -8 -31 -4 -16 7 -15 9 7 15
38 12 62 11 55 -1z m385 1 c0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -14 -22 -12
-44 5 -10 8 -23 12 -27 9 -5 -3 -9 0 -9 5 0 6 11 11 25 11 14 0 25 -4 25 -10z
m135 0 c-27 -12 -35 -12 -35 0 0 6 12 10 28 9 24 0 25 -1 7 -9z m-607 -20 c7
-11 9 -20 4 -20 -4 0 -13 9 -20 20 -7 11 -9 20 -4 20 4 0 13 -9 20 -20z m152
-4 c0 -8 -4 -18 -10 -21 -5 -3 -10 -4 -10 -1 0 2 -3 11 -6 20 -4 11 -1 16 10
16 9 0 16 -6 16 -14z m176 -7 c7 -13 -42 -22 -73 -14 l-28 7 30 12 c30 13 61
11 71 -5z m-272 -8 c3 -4 19 -11 36 -15 17 -3 38 -12 46 -20 12 -13 9 -14 -23
-9 -37 6 -82 31 -83 46 0 10 18 9 24 -2z m511 -20 c-19 -18 -89 -31 -100 -19
-7 8 -6 9 2 4 13 -7 75 21 82 37 2 5 8 4 14 -2 7 -7 7 -14 2 -20z m-307 -18
c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m199 -18 c9 -10 1
-14 -40 -19 -29 -3 -74 -5 -102 -4 l-50 1 55 8 c51 6 49 7 -30 7 -116 -1 -138
4 -60 12 36 3 90 3 120 -1 65 -8 76 -4 29 10 -30 9 -28 9 16 4 27 -3 55 -11
62 -18z"/>
<path d="M5778 8760 c2 -22 9 -40 14 -40 6 0 8 13 4 31 -3 17 -6 35 -6 40 0 5
-3 9 -8 9 -4 0 -6 -18 -4 -40z"/>
<path d="M5208 8643 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5995 9191 c3 -5 1 -12 -4 -15 -6 -4 -6 -18 -1 -36 5 -17 5 -28 0
-25 -11 7 -17 -80 -9 -130 5 -30 7 -33 8 -12 1 32 17 36 26 7 4 -13 0 -32 -11
-47 l-17 -27 -8 23 c-10 25 -16 56 -26 120 -4 32 -3 42 5 37 8 -5 12 2 12 18
0 14 -4 26 -10 26 -5 0 -10 9 -10 20 0 11 7 20 15 20 8 0 15 5 15 10 0 12 -6
13 -29 0 -37 -20 -11 -207 46 -328 45 -96 65 -125 74 -110 6 9 8 5 7 -12 -2
-25 65 -123 113 -166 31 -27 127 -94 135 -94 3 0 47 -20 98 -45 103 -50 243
-94 329 -102 56 -6 137 11 137 28 0 4 3 14 6 23 3 9 1 16 -5 16 -6 0 -11 -7
-11 -15 0 -9 -11 -20 -24 -25 -34 -12 -152 -6 -149 9 2 6 -9 12 -25 13 -15 1
9 5 53 9 44 5 91 10 105 12 14 3 30 5 36 6 23 2 21 93 -2 178 -34 120 -131
307 -142 273 -2 -6 5 -22 17 -34 12 -12 21 -27 21 -33 0 -6 7 -16 15 -23 19
-15 14 -23 -9 -14 -9 3 -16 15 -16 25 0 10 -5 19 -11 19 -7 0 -9 -9 -5 -24 4
-15 1 -26 -7 -30 -9 -3 -8 -8 4 -18 14 -11 14 -15 3 -26 -12 -10 -14 -10 -15
5 0 10 -2 53 -4 96 -4 75 -6 81 -42 121 -45 49 -151 131 -160 122 -7 -7 16
-36 29 -36 5 0 19 -10 31 -23 12 -12 41 -39 65 -60 23 -20 38 -37 33 -37 -5 0
-14 7 -21 15 -7 9 -22 12 -40 9 -23 -5 -28 -2 -34 20 -3 14 -11 26 -16 26 -6
0 -10 9 -10 20 0 15 -7 20 -26 20 -24 0 -26 3 -22 31 4 22 0 33 -14 40 -11 6
-18 6 -18 0 0 -5 6 -11 13 -14 6 -2 -1 -4 -18 -4 -16 0 -40 6 -52 14 -21 14
-23 14 -23 -1 0 -11 -9 -16 -30 -16 -16 0 -30 5 -30 10 0 6 9 10 19 10 11 0
23 5 26 10 3 6 1 10 -4 10 -6 0 -11 5 -11 11 0 13 39 2 61 -17 17 -15 39 -19
39 -6 0 10 -73 47 -150 76 -106 39 -342 73 -325 47z m115 -17 c0 -2 -7 -4 -15
-4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m63 -11 c-7 -2 -21 -2
-30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m85 -14 c39 -13 32 -29 -11 -28 -30
1 -30 1 -7 8 22 6 23 8 6 14 -10 4 -35 5 -55 2 -27 -4 -31 -3 -16 4 27 13 46
13 83 0z m-148 -8 l-35 -7 40 -13 c22 -7 68 -15 103 -18 63 -6 83 -16 64 -35
-7 -7 -48 -3 -126 13 -96 18 -116 25 -116 40 0 18 22 26 75 26 28 0 28 0 -5
-6z m-65 -90 c-9 -8 -55 -9 -55 -1 0 13 11 17 36 12 14 -2 22 -7 19 -11z m35
-51 c0 -27 -4 -50 -10 -50 -11 0 -14 83 -3 93 12 12 13 8 13 -43z m37 44 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m398 -82 c-9 -9 -35 8 -35
24 0 16 2 16 20 -1 11 -10 18 -20 15 -23z m-465 -66 c0 -16 -7 -26 -22 -29
-12 -4 -24 -4 -27 -1 -3 3 5 7 17 11 13 3 22 13 22 24 0 11 2 19 5 19 3 0 5
-11 5 -24z m20 -81 c-6 -46 -9 -55 -15 -37 -4 12 -11 22 -15 22 -4 0 -10 9
-13 20 -3 11 -1 20 4 20 5 0 9 -7 9 -15 0 -8 5 -15 11 -15 6 0 8 9 4 20 -3 11
0 26 7 34 7 8 13 13 14 12 0 0 -3 -28 -6 -61z m735 -144 c-3 -37 -1 -51 5 -41
8 12 10 11 11 -5 1 -17 2 -17 6 -2 7 25 23 21 23 -7 0 -34 -10 -56 -26 -56 -8
0 -11 6 -8 15 4 8 2 15 -3 15 -12 0 -47 105 -39 118 3 6 -2 13 -11 15 -10 3
-4 5 14 4 l32 -1 -4 -55z m-695 28 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8
5 12 10 9 6 -3 10 -10 10 -16z m35 1 c3 -11 13 -20 21 -20 14 0 18 -16 15 -52
-1 -10 4 -18 13 -18 9 0 16 -4 16 -9 0 -5 3 -16 6 -25 3 -9 1 -16 -5 -16 -6 0
-11 6 -11 13 0 7 -11 25 -25 40 -14 15 -23 27 -21 27 3 0 -3 15 -14 33 -22 36
-25 47 -11 47 5 0 13 -9 16 -20z m685 -36 c0 -8 -4 -12 -10 -9 -5 3 -10 13
-10 21 0 8 5 12 10 9 6 -3 10 -13 10 -21z m-590 -5 c0 -11 -4 -17 -10 -14 -5
3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m502 -91 c-9 -9 -12 -7 -12
12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m-505 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17
5 13 3 -3 4 -12 1 -19z m83 -18 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m8 -18 c18 -2 39 1 47 8 8 6 19 9 26 5 8 -6 7 -10
-2 -16 -8 -4 -14 -17 -15 -28 -1 -15 10 -23 42 -34 35 -11 44 -12 44 -1 0 7
-10 15 -22 17 -20 5 -20 5 2 4 20 -2 25 -8 26 -29 1 -16 -1 -28 -3 -28 -10 0
-122 56 -165 83 -48 30 -63 46 -30 32 9 -5 32 -10 50 -13z m162 3 c10 -12 8
-16 -11 -24 -28 -10 -59 3 -59 24 0 20 54 20 70 0z m70 -60 c0 -28 -15 -34
-24 -9 -10 25 -7 34 9 34 9 0 15 -9 15 -25z m-4 -60 c14 -13 14 -15 0 -15 -9
0 -16 4 -16 9 0 5 -12 12 -27 14 l-28 4 28 2 c16 0 35 -6 43 -14z m106 -12
c-37 -4 -40 -6 -28 -19 8 -8 21 -14 28 -15 7 0 8 -3 2 -5 -6 -2 -28 3 -50 12
-34 15 -37 18 -19 25 11 4 40 7 65 7 l45 -1 -43 -4z"/>
<path d="M8025 9163 c-48 -12 -94 -89 -80 -132 3 -11 28 -49 55 -86 40 -55 48
-71 39 -86 -25 -48 -119 4 -119 66 0 27 -5 33 -36 44 -30 11 -38 11 -45 0 -28
-44 13 -128 85 -176 83 -56 176 -41 215 34 22 44 13 79 -40 148 -53 70 -61
108 -23 113 31 4 74 -34 74 -67 0 -23 6 -29 37 -39 20 -6 39 -9 42 -6 3 2 4
20 4 40 -3 88 -119 171 -208 147z"/>
<path d="M2547 9064 c-16 -15 -26 -32 -23 -42 14 -50 29 -133 24 -137 -3 -3
-36 8 -73 25 -37 17 -75 28 -84 25 -21 -7 -75 -55 -69 -61 3 -2 58 -26 123
-53 114 -47 119 -51 170 -115 l53 -66 34 27 c18 15 35 28 37 29 3 3 -53 75
-90 117 -9 10 -26 72 -39 147 -13 72 -26 130 -29 130 -4 0 -19 -12 -34 -26z"/>
<path d="M5961 9054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4025 9030 c-4 -6 -15 -8 -25 -5 -23 7 -224 -60 -264 -88 -17 -12
-40 -27 -51 -33 -14 -8 -20 -21 -19 -42 2 -37 64 -144 81 -138 7 3 10 -1 7 -8
-6 -17 61 -90 75 -82 7 4 8 1 5 -8 -3 -9 -2 -16 3 -16 4 0 19 -10 33 -23 45
-40 114 -87 129 -87 8 0 22 -6 30 -14 9 -8 51 -26 95 -40 62 -21 98 -26 175
-26 71 0 92 3 81 11 -12 8 -9 9 12 5 32 -7 108 8 158 31 192 88 217 105 207
145 -9 35 -98 169 -108 162 -5 -3 -9 4 -9 16 0 17 -4 20 -21 15 -13 -4 -27 0
-40 11 -10 9 -19 12 -19 6 0 -6 9 -16 20 -22 15 -8 17 -15 11 -28 -5 -9 -12
-25 -15 -34 -8 -25 -17 -23 -60 17 -40 36 -43 65 -7 65 11 0 23 5 26 10 4 6
11 8 16 5 5 -4 9 -1 9 6 0 7 9 2 20 -10 10 -13 23 -21 29 -19 17 5 -42 62
-114 109 -73 48 -204 99 -254 99 -17 0 -33 5 -36 10 -8 13 -172 13 -180 0z
m-17 -32 c10 -10 17 -10 32 0 17 11 20 10 20 -7 0 -14 -5 -18 -19 -14 -10 3
-22 0 -26 -7 -4 -7 -11 -8 -18 -2 -17 13 -67 21 -67 11 0 -5 15 -10 33 -10 19
-1 24 -3 12 -6 -11 -2 -30 -6 -43 -9 l-23 -5 28 -24 28 -24 -30 -1 c-17 0 -47
8 -66 18 -34 17 -37 17 -68 0 -18 -10 -37 -15 -43 -12 -7 4 -8 2 -4 -4 4 -7 2
-12 -3 -12 -6 0 -11 9 -11 20 0 14 7 20 23 22 36 2 41 3 55 12 7 4 20 5 28 2
8 -3 29 4 47 15 18 12 23 18 12 15 -64 -17 -70 -12 -10 8 82 28 97 30 113 14z
m240 -28 c11 0 10 -3 -2 -15 -16 -17 -52 -20 -61 -6 -3 5 3 12 12 15 10 3 7 4
-7 2 -20 -2 -26 2 -28 20 l-2 22 37 -19 c21 -11 43 -19 51 -19z m-8 31 c0 -5
8 -7 19 -4 10 3 22 0 25 -6 10 -16 -34 -13 -64 4 -25 14 -25 14 -2 15 12 0 22
-4 22 -9z m88 -32 c-6 -4 -17 -5 -22 -2 -6 4 -5 8 2 11 7 2 9 9 5 15 -4 7 1 6
11 -2 13 -11 14 -16 4 -22z m77 -11 c14 -6 25 -15 25 -20 0 -4 12 -8 26 -8 22
0 24 -3 15 -14 -8 -10 -9 -16 -1 -21 5 -3 10 -1 10 4 0 8 6 7 19 -1 21 -13 17
-38 -6 -38 -13 0 -13 3 -3 15 11 13 6 14 -40 11 -51 -5 -52 -4 -34 15 21 23
15 31 -24 34 -36 3 -54 13 -47 26 8 12 29 11 60 -3z m-712 -76 c-10 -10 -20
-17 -22 -16 -6 5 20 34 30 34 5 0 1 -8 -8 -18z m512 8 c4 -6 -5 -10 -19 -10
-14 0 -26 5 -26 10 0 6 9 10 19 10 11 0 23 -4 26 -10z m-365 -25 c0 -21 -27
-30 -45 -15 -19 16 -10 30 21 30 15 0 24 -6 24 -15z m-95 -55 c4 -12 14 -17
30 -16 34 4 54 -9 37 -26 -29 -29 3 -76 48 -70 41 6 97 -16 103 -40 9 -32 3
-33 -36 -9 -38 24 -67 27 -85 9 -9 -9 -16 -7 -32 7 -11 10 -17 22 -14 26 3 5
-4 19 -15 31 -12 12 -21 27 -21 32 0 6 -5 4 -11 -4 -8 -12 -9 -11 -4 6 3 13
-1 26 -10 34 -8 7 -15 9 -15 4 0 -5 -4 -3 -9 5 -5 7 -6 22 -4 32 4 15 9 17 19
8 7 -6 16 -19 19 -29z m909 -90 c2 0 2 10 -1 23 -6 21 -5 21 14 -3 25 -31 38
-70 24 -70 -6 0 -11 11 -11 25 0 14 -4 25 -10 25 -13 0 -13 -13 2 -45 7 -16
19 -25 35 -25 20 0 23 -4 17 -27 -4 -22 -3 -25 5 -13 9 13 11 12 11 -5 0 -11
-4 -26 -9 -34 -7 -11 -12 -9 -23 10 -49 80 -50 82 -58 68 -5 -7 -7 5 -4 29 3
32 0 42 -11 42 -16 0 -20 21 -8 34 4 4 11 -2 15 -14 4 -11 9 -20 12 -20z
m-654 -10 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16
-10z m-111 -102 c16 -18 27 -35 25 -37 -5 -6 -64 50 -64 61 0 15 10 8 39 -24z
m26 21 c11 -17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m85 -47 c0
-23 19 -42 41 -42 9 0 19 -7 23 -15 3 -8 9 -14 13 -13 4 1 22 -5 38 -14 l30
-16 -29 -8 c-26 -6 -33 -3 -57 26 -21 24 -33 31 -50 27 -12 -3 -37 2 -58 14
-19 10 -29 19 -21 19 8 0 27 -7 43 -14 30 -16 35 -12 12 10 -8 8 -12 21 -9 29
8 21 24 19 24 -3z m669 -11 c18 -18 18 -19 -3 -28 -26 -11 -76 -5 -76 8 0 6
13 8 33 5 17 -3 27 -3 20 0 -7 3 -9 12 -6 20 7 18 9 18 32 -5z m-475 -19 c3
-5 33 -10 67 -11 68 -2 79 -6 78 -24 0 -7 -2 -7 -5 -2 -2 6 -36 9 -80 7 -46
-2 -81 1 -87 7 -6 6 -19 11 -29 11 -10 0 -18 5 -18 10 0 12 67 14 74 2z m299
-4 c31 -15 35 -28 10 -28 -28 0 -94 26 -86 33 10 11 47 8 76 -5z m87 -3 c0 -8
-4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m10 -40
c-7 -8 -27 -15 -44 -14 l-31 0 35 13 c48 18 55 19 40 1z m-170 -36 c-23 -6
-22 -7 10 -9 l35 -2 -28 -8 c-16 -5 -34 -7 -40 -5 -18 5 -244 14 -232 9 6 -2
44 -8 85 -14 l75 -9 -76 4 c-73 4 -137 20 -127 31 3 3 39 7 79 9 46 2 67 0 54
-5 -33 -14 64 -11 105 3 19 7 46 11 60 8 l25 -5 -25 -7z m94 -1 c-14 -6 -28
-7 -38 -1 -12 7 -7 9 21 10 33 1 35 -1 17 -9z"/>
<path d="M3978 8993 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7075 8879 c-43 -83 -65 -279 -46 -414 6 -44 16 -84 21 -89 6 -6 10
-19 10 -30 1 -23 39 -99 84 -167 45 -68 155 -176 224 -220 65 -41 181 -92 239
-104 30 -6 33 -3 52 32 19 39 42 138 40 177 -1 18 -2 18 -9 -4 -4 -14 -7 34
-6 112 1 86 4 129 9 115 5 -12 6 -62 3 -110 -8 -99 8 -114 19 -19 5 43 1 79
-15 143 -25 105 -69 224 -103 281 -34 58 -173 198 -196 198 -5 0 -14 -7 -21
-15 -9 -11 -16 -12 -31 -2 -14 9 -21 9 -31 -1 -6 -6 -22 -12 -34 -12 -13 0
-29 -7 -38 -15 -8 -8 -23 -15 -34 -15 -20 0 -32 33 -32 86 l0 24 21 -21 c16
-16 18 -23 8 -35 -6 -8 -9 -14 -6 -14 10 0 110 27 130 35 13 6 20 2 24 -13 4
-18 7 -19 19 -7 19 18 18 29 -2 21 -8 -3 -12 -2 -9 4 9 14 -158 89 -220 98
-55 9 -56 9 -70 -19z m23 -3 c-7 -8 -15 -12 -17 -11 -5 6 10 25 20 25 5 0 4
-6 -3 -14z m62 -10 c0 -37 -17 -40 -29 -6 -9 27 -8 30 10 30 14 0 19 -7 19
-24z m60 -6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m-116 -42 c-8 -51 -30 -81 -40 -55 -3 10 3 27 15 43 12 15 21 33 21
40 0 8 2 14 5 14 3 0 2 -19 -1 -42z m-28 -4 c-4 -9 -9 -15 -11 -12 -3 3 -3 13
1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z m240 -2 c11 -6 -1 -8 -38 -7 -29 2
-55 4 -58 5 -3 1 -8 2 -12 1 -5 0 -8 3 -8 7 0 11 95 6 116 -6z m-176 -62 c0
-16 -4 -30 -10 -30 -11 0 -14 43 -3 53 11 12 13 8 13 -23z m280 0 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-70 -20 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m98 -23
c2 -19 8 -27 20 -25 11 2 16 -3 14 -14 -2 -13 4 -18 23 -18 16 0 25 -6 25 -15
0 -8 5 -15 11 -15 13 0 48 -41 48 -55 0 -5 -10 3 -23 18 l-23 29 -7 -26 c-6
-26 -29 -35 -41 -16 -3 6 1 10 10 10 11 0 15 8 13 30 -2 25 -7 30 -28 30 -32
0 -50 17 -50 47 0 18 -7 25 -30 30 -40 8 -38 21 3 19 27 -1 32 -6 35 -29z
m-68 -7 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m-343 -147 c-2 -21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2 -55 0 -75z m583
-71 c0 -7 5 -10 12 -6 8 5 9 2 5 -10 -4 -10 -7 -24 -7 -32 0 -8 -4 -14 -10
-14 -5 0 -10 15 -10 34 0 39 -25 57 -34 24 -4 -18 -7 -19 -20 -8 -23 18 -20
50 3 50 10 0 22 8 26 18 6 14 9 12 21 -13 7 -16 13 -36 14 -43z m-85 -61 c3
-5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m70 -2 c10 -15 -1 -23 -20
-15 -9 3 -13 10 -10 16 8 13 22 13 30 -1z m-496 -47 c-2 -15 1 -33 5 -41 6
-10 5 -12 -2 -7 -16 9 -15 -7 0 -22 6 -6 8 -15 4 -19 -4 -5 -14 1 -22 11 -16
23 -19 76 -4 76 6 0 10 7 10 15 0 8 3 15 6 15 3 0 4 -12 3 -28z m35 7 c-8 -14
-24 -10 -24 6 0 9 6 12 15 9 8 -4 12 -10 9 -15z m56 2 c0 -27 -21 -81 -31 -81
-11 0 -12 25 -3 69 7 33 34 43 34 12z m-60 -118 c0 -5 7 -17 16 -28 12 -17 16
-18 24 -5 5 8 12 11 17 7 4 -5 1 -12 -7 -17 -12 -7 -12 -12 -2 -22 6 -6 12
-25 12 -40 0 -23 4 -28 25 -28 24 0 30 -9 26 -37 -1 -7 3 -13 9 -13 14 0 13
45 -2 58 -7 5 -22 12 -33 15 -16 4 -15 5 6 6 31 1 49 -16 49 -46 0 -12 8 -27
18 -32 16 -10 16 -10 0 -11 -10 0 -18 -5 -18 -12 0 -6 3 -8 6 -5 12 11 44 -13
44 -33 0 -33 18 -22 22 13 l3 32 6 -37 c4 -32 2 -38 -14 -38 -10 0 -21 9 -24
20 -3 11 -9 20 -15 20 -15 0 -129 121 -123 131 4 5 1 9 -5 9 -6 0 -14 8 -17
17 -4 10 -18 28 -31 40 -13 12 -21 27 -17 32 7 13 25 15 25 4z m380 -193 c0
-5 -9 -10 -20 -10 -11 0 -23 -7 -26 -15 -11 -28 -38 -35 -72 -18 -44 21 -40
32 9 26 32 -5 44 -2 51 11 10 17 58 23 58 6z m-90 -80 c8 -5 12 -11 10 -14 -3
-2 -14 2 -24 10 -22 15 -10 19 14 4z m92 -36 c4 -49 -1 -58 -26 -48 -13 5 -14
9 -5 20 6 8 9 24 5 37 -5 23 0 37 15 37 4 0 9 -21 11 -46z m-99 12 c27 -21 20
-28 -13 -11 -16 9 -30 18 -30 20 0 10 25 4 43 -9z m210 -53 c7 21 19 0 12 -22
-4 -15 -15 -21 -36 -21 -16 0 -29 5 -29 11 0 6 9 8 23 4 16 -5 18 -4 7 4 -8 5
-28 7 -45 4 -20 -4 -27 -2 -20 5 14 15 47 20 67 10 10 -5 18 -3 21 5z"/>
<path d="M8404 8778 c-48 -56 -108 -124 -131 -150 -24 -26 -43 -51 -43 -55 0
-11 177 -163 188 -163 14 0 42 35 41 51 0 8 -25 34 -54 59 -30 25 -53 48 -52
52 3 9 189 221 202 230 6 5 -5 22 -26 44 l-36 36 -89 -104z"/>
<path d="M2191 8771 l-43 -36 52 -120 c61 -143 112 -259 116 -264 2 -2 17 7
33 19 30 21 31 22 16 57 -19 45 -14 59 37 103 l40 34 40 -23 41 -23 34 34 34
34 -179 110 -179 111 -42 -36z m137 -133 c23 -16 42 -32 42 -37 0 -4 -13 -17
-30 -29 l-30 -23 -25 57 c-38 86 -38 86 43 32z"/>
<path d="M8609 8523 c-167 -171 -158 -157 -124 -193 16 -17 32 -30 35 -30 4 0
69 66 145 146 l139 146 -34 29 c-18 16 -33 29 -34 29 -1 0 -58 -57 -127 -127z"/>
<path d="M1994 8569 c-20 -5 -59 -38 -103 -85 -67 -71 -83 -98 -62 -106 5 -1
66 -57 136 -123 70 -66 132 -121 139 -123 6 -1 25 11 41 28 l30 32 -52 47
c-29 25 -53 50 -53 55 0 4 20 31 45 58 38 42 45 57 45 92 0 57 -38 112 -88
125 -20 6 -39 11 -42 10 -3 0 -19 -5 -36 -10z m54 -95 c33 -23 29 -64 -11
-102 l-33 -32 -38 39 -38 39 35 36 c39 40 52 43 85 20z"/>
<path d="M3090 8526 c-84 -19 -149 -45 -214 -88 -32 -21 -60 -38 -63 -38 -2 0
-33 -30 -69 -66 -71 -74 -71 -73 -6 -139 92 -94 124 -118 134 -104 8 11 9 10
4 -4 -4 -11 0 -20 12 -24 9 -4 19 -9 22 -12 3 -4 28 -18 55 -33 132 -70 334
-88 498 -44 101 28 229 99 220 122 -3 9 0 12 9 9 21 -8 128 91 128 119 0 13
-29 51 -75 99 -42 42 -78 77 -79 77 -2 0 -33 17 -67 39 -149 89 -345 123 -509
87z m200 -7 c-11 -8 -6 -9 20 -5 19 3 58 -1 85 -8 28 -8 41 -15 30 -15 -11 -1
-31 -4 -44 -8 -16 -4 -22 -3 -17 5 4 8 -3 8 -26 1 -43 -12 -70 -11 -43 2 14 6
1 7 -40 4 -149 -10 -200 -18 -208 -31 -9 -15 -84 -17 -93 -2 -3 4 2 8 10 8 8
0 27 5 43 11 15 7 44 11 63 11 27 -1 30 0 15 7 -16 6 -12 9 20 14 22 4 44 2
49 -3 6 -6 19 -9 31 -9 16 1 17 2 3 6 -41 10 -13 23 50 23 50 0 63 -3 52 -11z
m279 -81 c36 -17 59 -34 52 -36 -7 -2 -16 0 -20 5 -3 5 -26 15 -51 22 -25 7
-58 19 -75 26 l-30 14 29 0 c16 1 59 -13 95 -31z m-279 12 c11 -7 -9 -10 -67
-10 -45 0 -84 4 -87 9 -8 12 136 13 154 1z m83 -17 c-7 -3 -13 -11 -13 -19 0
-10 -13 -14 -45 -14 -55 0 -60 17 -7 25 20 3 44 10 52 14 8 5 17 6 20 3 3 -2
-1 -7 -7 -9z m-413 -2 c0 -5 -3 -11 -7 -14 -5 -2 -3 -2 4 -1 7 2 15 -1 18 -6
3 -4 -15 -8 -40 -8 -24 1 -42 5 -39 10 3 4 1 8 -5 8 -5 0 -13 -4 -16 -10 -3
-5 -11 -10 -18 -10 -6 0 -2 6 9 14 10 8 29 12 40 10 12 -3 25 -1 28 5 9 13 26
14 26 2z m580 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m-450 -10 c0 -5 -21 -10 -46 -10 -27 0 -43 4 -39 10 3 6 24 10
46 10 21 0 39 -4 39 -10z m487 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m-759 -56 c-2 -6 -12 -12 -22 -14 -10 -1 -21 -6 -24 -10 -4 -4 1 -4
12 0 14 5 17 2 12 -9 -4 -11 1 -15 19 -15 15 0 25 6 25 14 0 7 7 19 16 27 13
10 17 10 24 -1 11 -17 2 -40 -16 -40 -8 0 -17 -7 -20 -15 -4 -8 -14 -15 -23
-15 -10 0 -26 -6 -36 -14 -17 -12 -16 -15 21 -53 21 -22 47 -43 57 -46 20 -7
21 -16 6 -37 -8 -11 -12 -12 -16 -2 -2 6 -11 12 -18 12 -8 0 -19 9 -25 20 -6
11 -15 18 -21 15 -8 -6 -13 8 -10 28 0 4 -5 7 -13 7 -8 0 -13 8 -12 18 0 9 -6
17 -14 17 -20 0 -43 22 -35 35 4 6 11 8 16 5 5 -4 9 1 9 9 0 8 8 20 18 25 9 6
13 11 8 11 -13 0 38 39 52 40 8 0 12 -5 10 -12z m885 -6 c15 -16 15 -18 -3
-18 -13 0 -20 7 -20 18 0 10 2 18 3 18 2 0 11 -8 20 -18z m45 -59 c2 -8 -5
-13 -17 -13 -12 0 -21 6 -21 16 0 18 31 15 38 -3z m62 -55 c0 -5 -7 -17 -16
-25 -13 -14 -17 -14 -25 -2 -6 10 -8 5 -6 -16 2 -27 -1 -30 -28 -28 -16 0 -24
-1 -17 -4 16 -6 -3 -33 -24 -33 -25 0 -15 22 26 57 33 28 40 41 40 71 l0 35
25 -23 c14 -13 25 -28 25 -32z m-110 28 c0 -8 7 -17 16 -20 20 -8 9 -26 -17
-26 -14 0 -19 7 -19 30 0 17 5 30 10 30 6 0 10 -6 10 -14z m-820 -32 c0 -45
-24 -42 -28 4 -2 24 1 32 12 32 12 0 16 -10 16 -36z m116 -154 c13 0 24 -5 24
-11 0 -6 -9 -9 -20 -6 -11 3 -20 2 -20 -3 0 -11 38 -20 42 -9 5 14 78 24 78
10 0 -6 -14 -11 -31 -11 -23 0 -30 -4 -26 -14 3 -8 -2 -17 -11 -21 -9 -3 -18
-1 -20 4 -2 6 -9 11 -15 11 -7 0 -27 9 -46 21 -27 16 -31 23 -20 30 8 5 20 7
27 4 8 -3 25 -5 38 -5z m204 0 c0 -5 -16 -10 -36 -10 -21 0 -33 4 -29 10 3 6
19 10 36 10 16 0 29 -4 29 -10z m310 0 c0 -5 -13 -10 -29 -10 -17 0 -33 5 -36
10 -4 6 8 10 29 10 20 0 36 -4 36 -10z m80 -4 c-6 -6 -5 -15 1 -23 9 -10 6
-13 -15 -13 -14 0 -26 -4 -26 -8 0 -7 -83 -33 -115 -37 -5 -1 -21 -4 -35 -8
-14 -4 -38 -3 -55 3 l-30 9 30 -1 c53 -2 76 2 70 11 -6 10 9 16 68 26 20 3 37
11 37 18 0 7 4 6 10 -3 7 -12 12 -12 22 -2 8 8 8 12 1 12 -6 0 -13 6 -16 13
-3 8 6 12 29 12 20 0 29 -4 24 -9z m-190 -20 c0 -17 -27 -29 -35 -16 -4 6 -41
11 -83 12 l-77 2 75 6 c41 4 85 7 98 8 13 1 22 -4 22 -12z m-209 -40 c5 3 9 0
9 -5 0 -6 19 -12 43 -13 l42 -1 -45 -7 c-42 -7 -40 -7 30 -10 65 -3 68 -4 25
-8 -27 -2 -70 0 -95 4 -39 6 -41 8 -20 13 21 6 22 8 8 14 -10 3 -18 12 -17 19
0 10 2 10 6 0 2 -6 9 -10 14 -6z m155 1 c4 -4 -25 -7 -64 -7 -40 0 -72 4 -72
8 0 10 126 9 136 -1z m-196 -28 c0 -11 -7 -11 -39 -2 -23 6 -23 7 -5 13 21 8
44 2 44 -11z"/>
<path d="M2845 8140 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M3773 8229 c-1 -10 2 -19 8 -19 11 0 12 27 1 34 -4 2 -9 -4 -9 -15z"/>
<path d="M3690 8140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2945 8060 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8737 8373 l-148 -126 27 -28 27 -28 81 66 c45 37 96 80 113 97 18
16 35 27 38 24 3 -3 -33 -63 -80 -134 -47 -70 -85 -131 -85 -135 0 -13 31 -39
45 -39 8 0 77 26 154 59 137 57 154 59 101 15 -14 -12 -35 -30 -46 -40 -12
-11 -49 -41 -83 -69 -33 -27 -61 -52 -61 -55 0 -3 12 -21 28 -39 l27 -33 153
127 c83 70 152 131 152 135 0 10 -82 110 -90 110 -4 0 -47 -17 -96 -39 -148
-64 -144 -66 -70 46 36 54 66 102 66 108 0 15 -80 105 -93 104 -7 0 -79 -57
-160 -126z"/>
<path d="M5098 8373 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6838 8368 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M8069 8308 c-52 -73 -81 -136 -70 -156 9 -14 10 -13 11 11 0 15 5 27
11 27 6 0 9 -7 6 -14 -4 -10 -1 -13 9 -9 16 6 19 33 4 33 -5 0 -10 10 -10 23
0 18 2 19 10 7 8 -12 11 -13 19 -1 5 7 6 23 4 35 -4 17 -3 18 7 6 9 -12 10
-11 5 9 -5 15 -3 21 3 17 6 -3 13 3 17 14 3 11 15 20 25 20 21 0 18 -22 -6
-50 -7 -8 -16 -27 -19 -42 -4 -16 -12 -25 -18 -21 -6 4 -8 -4 -5 -20 3 -15 1
-24 -4 -21 -5 3 -8 -2 -6 -12 2 -12 -7 -23 -27 -33 -38 -18 -45 -28 -45 -68 0
-17 -4 -34 -9 -38 -6 -3 -7 -28 -4 -55 4 -34 2 -50 -6 -50 -7 0 -11 -28 -12
-82 -2 -100 -6 -85 -12 34 -3 72 -1 85 10 77 10 -9 13 -6 13 15 0 14 -4 26
-10 26 -6 0 -6 9 -1 23 15 37 33 128 28 133 -6 6 -28 -68 -52 -170 -15 -65
-22 -222 -10 -234 2 -3 16 0 30 6 24 11 25 10 15 -7 -13 -25 -14 -69 -1 -55 5
5 14 25 19 44 9 31 10 32 11 9 1 -15 -5 -40 -12 -57 -10 -24 -14 -27 -25 -16
-6 7 -12 22 -12 33 0 11 -4 23 -10 26 -12 8 -12 8 1 -57 19 -93 51 -183 72
-199 11 -9 17 -21 13 -27 -6 -10 39 -84 92 -151 16 -20 22 -22 40 -12 18 10
22 9 22 -3 0 -21 21 -31 31 -15 4 7 12 10 16 5 4 -5 2 -11 -4 -13 -7 -3 -13
-9 -13 -14 0 -11 42 11 53 29 9 14 97 17 97 2 0 -5 -9 -10 -20 -10 -11 0 -20
-5 -20 -11 0 -7 -11 -8 -30 -4 -36 8 -37 3 -9 -41 21 -35 36 -45 25 -19 -7 18
15 20 32 3 8 -8 12 -9 12 -1 0 6 18 34 40 62 22 28 40 60 40 71 0 30 -18 24
-23 -7 -3 -23 -4 -21 -6 10 -1 27 3 37 14 37 8 0 15 -5 15 -11 0 -6 9 -8 20
-4 24 8 26 -7 4 -26 -8 -7 -13 -21 -11 -31 3 -11 -3 -24 -15 -32 -11 -8 -21
-26 -22 -39 0 -14 -3 -25 -6 -25 -28 2 -43 -6 -53 -31 -7 -15 -15 -29 -19 -29
-11 -2 -63 49 -61 59 4 14 -15 11 -20 -3 -3 -8 -6 -6 -6 5 -1 9 -8 17 -16 17
-7 0 -18 8 -24 17 -5 10 -13 15 -16 11 -9 -8 3 -20 65 -68 25 -19 47 -37 50
-41 3 -3 16 -12 29 -19 23 -12 27 -10 48 16 13 16 38 58 55 94 17 36 38 81 48
100 10 19 23 60 30 90 6 30 16 73 22 96 12 51 -6 279 -28 356 -32 110 -123
267 -198 342 -64 64 -174 146 -196 146 -5 0 -24 -19 -41 -42z m149 -48 c7 0
15 -7 18 -15 4 -8 11 -15 16 -15 15 0 78 -62 72 -72 -8 -13 -24 -9 -24 5 0 17
-38 52 -47 44 -3 -4 4 -16 17 -27 15 -13 18 -20 9 -20 -20 0 -62 47 -54 60 4
7 -2 14 -14 17 -11 3 -18 9 -16 14 3 4 -4 13 -15 19 -11 6 -20 16 -20 22 0 6
10 2 23 -10 13 -12 29 -22 35 -22z m-48 -12 c0 -4 -7 -8 -15 -8 -8 0 -15 4
-15 8 0 5 7 9 15 9 8 0 15 -4 15 -9z m4 -66 c-12 -9 -24 -24 -27 -32 -3 -8 -6
-2 -6 13 -1 16 4 38 10 49 10 19 12 19 27 4 16 -16 16 -18 -4 -34z m81 -52
c18 -20 17 -20 -17 -20 -39 0 -49 6 -41 26 8 20 37 17 58 -6z m118 -38 c22
-23 28 -35 20 -43 -7 -7 -17 1 -34 28 -12 21 -27 41 -32 44 -5 4 -6 13 -1 20
6 10 9 10 12 -1 2 -8 18 -30 35 -48z m-253 8 c0 -11 -6 -20 -14 -20 -18 0 -29
16 -21 30 11 18 35 11 35 -10z m220 -71 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16
0 8 5 12 10 9 6 -3 10 -10 10 -16z m76 -14 c-3 -8 -1 -15 3 -15 5 0 15 -15 22
-32 7 -18 14 -36 17 -40 2 -5 -1 -8 -7 -8 -6 0 -11 7 -11 15 0 8 -4 15 -10 15
-5 0 -10 -7 -10 -16 0 -18 -51 -16 -57 2 -6 17 29 27 39 12 6 -8 8 2 5 29 -4
38 -1 53 11 53 2 0 2 -7 -2 -15z m-419 -42 c-3 -10 -5 -2 -5 17 0 19 2 27 5
18 2 -10 2 -26 0 -35z m0 -170 c-2 -21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2
-55 0 -75z m502 16 c8 -38 8 -56 -1 -72 -12 -21 -13 -19 -18 21 -6 39 -2 102
5 102 2 0 8 -23 14 -51z m24 -184 c3 -41 1 -62 -4 -50 -5 13 -8 -8 -9 -60 -1
-44 -8 -96 -16 -115 -13 -32 -14 -33 -8 -5 3 17 10 93 14 170 10 181 14 192
23 60z m-36 53 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-491
-95 c0 -48 -2 -59 -7 -40 -4 16 -13 35 -20 44 -11 12 -10 17 4 24 9 6 17 17
17 24 0 8 1 15 3 15 2 0 3 -30 3 -67z m491 -40 c-3 -10 -5 -2 -5 17 0 19 2 27
5 18 2 -10 2 -26 0 -35z m-424 -60 c7 -37 5 -37 -15 -2 -13 22 -14 29 -4 29 8
0 16 -12 19 -27z m424 -25 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0
-40z m-417 -38 c0 8 2 11 5 8 3 -3 2 -16 -3 -29 l-9 -23 -12 21 c-15 30 -14
38 4 23 12 -10 15 -10 15 0z m60 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z m28 -60 c-1 -32 -4 -38 -15 -29 -10 9 -13 8
-13 -4 0 -9 8 -20 17 -26 10 -5 14 -13 10 -17 -11 -11 -50 35 -43 52 3 7 10
14 16 14 5 0 10 11 10 25 0 14 4 25 10 25 5 0 9 -18 8 -40z m-63 -19 c-6 -5
-25 10 -25 20 0 5 6 4 14 -3 8 -7 12 -15 11 -17z m293 -188 c-9 -10 -18 -25
-21 -33 -4 -11 -6 -9 -6 6 -1 21 16 44 33 44 5 0 2 -8 -6 -17z"/>
<path d="M8491 7774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1634 8197 l-31 -43 116 -169 c64 -93 120 -169 125 -169 5 -1 18 11
29 26 l20 26 -22 31 c-26 36 -23 52 23 105 l34 39 39 -16 39 -15 27 33 c15 19
27 38 27 42 0 4 -44 24 -98 44 -53 20 -140 53 -192 73 -53 20 -98 36 -100 36
-3 -1 -19 -20 -36 -43z m153 -99 c32 -12 60 -23 62 -24 5 -5 -40 -64 -49 -64
-8 0 -80 95 -80 105 0 8 4 7 67 -17z"/>
<path d="M5170 8179 c-106 -27 -252 -93 -229 -102 8 -3 4 -6 -9 -6 -27 -1 -93
-44 -148 -97 -32 -29 -36 -37 -24 -46 13 -8 13 -9 -1 -5 -23 7 -32 -1 -81 -78
-99 -155 -145 -338 -125 -502 l10 -83 39 0 c22 0 37 3 35 8 -3 4 24 7 59 7 37
0 68 5 72 11 4 7 20 12 36 12 34 -1 274 119 290 144 6 10 15 18 19 18 5 0 18
11 28 24 11 14 28 28 39 31 11 4 20 12 20 20 0 7 11 26 24 42 30 36 75 119
111 208 27 66 29 79 29 219 1 111 -2 150 -11 153 -7 3 -13 11 -13 19 0 11 -16
14 -67 13 -38 0 -84 -5 -103 -10z m27 -15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m117 0 c9 -3 16 -15 16 -25 0 -12 -5 -16 -15 -13 -11 5 -15
-1 -15 -20 0 -14 -4 -26 -9 -26 -12 0 -23 48 -16 71 7 20 14 23 39 13z m-79
-26 c29 -13 32 -23 9 -32 -31 -12 -44 -6 -44 19 0 29 0 29 35 13z m-125 -8
c-20 -13 -53 -13 -45 0 3 6 18 10 33 10 21 0 24 -2 12 -10z m9 -26 c2 -2 -13
-4 -35 -4 -29 0 -35 -3 -24 -10 12 -7 8 -10 -15 -10 -16 0 -37 5 -45 11 -12 8
-8 9 15 5 17 -3 24 -3 18 0 -7 3 -13 9 -13 14 0 7 90 2 99 -6z m224 -54 c11
-52 -4 -219 -19 -213 -12 4 -15 -5 -29 -90 -6 -37 -7 -38 -25 -22 -13 12 -27
15 -49 10 -24 -5 -31 -3 -31 9 0 12 10 16 38 16 46 0 67 13 58 35 -3 9 0 23 8
30 16 17 29 104 20 140 -7 30 4 115 15 115 5 0 11 -13 14 -30z m-388 0 c3 -5
-3 -10 -15 -10 -11 0 -23 -7 -26 -15 -6 -15 -44 -21 -44 -7 0 8 56 40 72 41 4
1 10 -3 13 -9z m-6 -62 c-14 -51 -61 -65 -67 -20 -2 14 -10 22 -23 22 -10 0
-19 5 -19 10 0 6 6 10 14 10 8 0 18 -4 21 -10 3 -5 20 -10 37 -10 23 0 29 4
24 15 -3 9 0 15 9 15 11 0 12 -7 4 -32z m348 0 c-3 -7 -5 -2 -5 12 0 14 2 19
5 13 2 -7 2 -19 0 -25z m-457 -59 c0 -4 -9 -9 -20 -12 -12 -3 -20 -14 -20 -26
0 -12 -4 -21 -9 -21 -13 0 -22 44 -10 49 7 2 6 7 -2 12 -12 8 -12 10 -1 18 14
8 62 -7 62 -20z m460 -19 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-585 -67 c-7 -46 -11 -51 -20 -22 -5 14 -3 25 4 27 6 2
11 10 11 18 0 8 3 14 6 14 3 0 3 -17 -1 -37z m63 -30 c4 -24 -26 -40 -34 -18
-8 21 3 47 19 43 6 -3 13 -14 15 -25z m552 -9 c0 -8 -5 -12 -10 -9 -6 4 -8 11
-5 16 9 14 15 11 15 -7z m-649 -31 c-10 -17 -10 -16 -11 3 0 23 13 41 18 25 2
-6 -1 -18 -7 -28z m34 -3 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9
10 6 0 13 -4 16 -10z m-77 -65 c-3 -14 -2 -25 3 -25 4 0 1 -12 -7 -27 -7 -16
-14 -44 -14 -64 0 -61 -22 -149 -36 -149 -16 0 -17 10 -3 69 5 24 10 64 11 87
0 24 5 41 10 38 5 -3 6 7 3 23 -4 20 -3 23 3 9 6 -14 11 -16 16 -8 4 7 4 17 1
22 -7 11 3 50 12 50 3 0 3 -11 1 -25z m539 -42 c-3 -27 -11 -50 -21 -55 -23
-13 -20 -38 4 -38 11 0 20 -6 20 -13 0 -17 -50 -63 -79 -72 -11 -3 -24 -17
-27 -31 -8 -30 -24 -32 -24 -2 0 27 27 58 52 58 10 0 18 7 18 15 0 8 5 15 10
15 14 0 12 28 -2 33 -10 3 -8 12 5 36 10 17 18 41 17 54 -4 38 0 47 16 47 14
0 16 -8 11 -47z m57 -25 c3 5 3 18 -1 28 -6 16 -5 17 9 4 8 -8 13 -22 10 -30
-9 -24 -51 -65 -58 -57 -4 4 -2 7 4 7 6 0 9 8 6 18 -5 15 -4 15 6 2 10 -13 11
-12 7 5 -3 11 -8 31 -11 45 -6 22 -5 22 7 -4 8 -16 17 -24 21 -18z m-174 -122
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-27
-82 c8 -8 -33 -41 -53 -45 -8 -1 -23 1 -33 6 -22 9 -28 4 -30 -24 -1 -16 -6
-21 -19 -18 -9 3 -16 1 -15 -5 2 -10 -72 -40 -84 -32 -15 9 -68 -9 -72 -25 -3
-10 -5 -8 -6 7 -1 16 7 26 26 33 15 6 31 16 35 22 5 9 10 8 20 -2 11 -12 10
-13 -2 -6 -12 6 -13 5 -3 -5 9 -10 18 -11 35 -2 13 6 17 11 9 11 -11 1 -10 5
5 16 18 14 17 14 -13 15 -26 0 -33 4 -33 19 0 11 5 23 10 26 6 3 10 -1 10 -9
0 -23 38 -30 65 -12 13 9 35 16 49 16 14 0 28 5 31 10 7 11 58 14 68 4z m-434
-101 c-1 -47 -1 -48 -11 -16 -6 18 -8 39 -5 47 11 29 17 17 16 -31z m148 21
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-130 -80 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M4980 7420 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M9159 8023 c-47 -37 -118 -93 -157 -122 -40 -30 -72 -57 -72 -60 0
-4 13 -21 28 -39 l27 -33 155 120 c85 66 156 124 157 128 2 5 -9 23 -24 41
l-28 33 -86 -68z"/>
<path d="M1508 8035 c-24 -32 -23 -39 17 -113 20 -35 34 -66 32 -68 -2 -3 -35
0 -73 6 -38 5 -74 10 -80 10 -12 0 -70 -78 -63 -84 2 -2 62 -11 132 -20 125
-15 129 -16 191 -61 35 -25 67 -45 72 -45 5 0 19 16 32 35 l22 35 -72 54 c-70
51 -76 59 -128 165 -30 61 -57 111 -60 111 -3 0 -13 -11 -22 -25z"/>
<path d="M4255 7954 c-84 -7 -153 -28 -244 -71 -92 -44 -162 -95 -148 -109 4
-4 19 5 33 19 15 15 35 27 45 27 10 0 22 7 27 16 4 8 33 26 63 40 45 20 50 21
29 4 -14 -11 -35 -29 -47 -40 -12 -11 -26 -20 -30 -20 -5 0 -14 -9 -21 -21 -9
-13 -20 -19 -32 -16 -12 3 -17 0 -14 -7 3 -8 -9 -12 -41 -11 -42 2 -45 0 -40
-19 4 -14 3 -17 -3 -8 -6 9 -14 6 -31 -12 -13 -14 -20 -29 -17 -35 7 -11 37
-3 48 13 5 7 8 6 8 -3 0 -7 -16 -28 -35 -47 -24 -22 -35 -42 -35 -61 0 -23 -2
-25 -11 -13 -9 12 -10 10 -6 -9 7 -31 -8 -70 -30 -78 -26 -9 -53 -40 -53 -60
0 -14 -2 -15 -9 -4 -5 8 -5 21 -1 30 5 9 15 31 22 49 12 31 28 43 28 22 0 -5
5 -10 10 -10 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0 -10 9 -10 20 0 12 5 17 14
14 7 -3 16 2 19 10 4 11 1 13 -10 9 -13 -5 -14 -3 -3 17 8 15 15 19 19 11 7
-11 31 27 31 49 0 33 -52 -34 -97 -125 -55 -109 -67 -164 -68 -310 -1 -77 0
-148 3 -159 5 -18 12 -18 106 -12 56 3 124 13 151 21 59 18 235 105 235 117 0
4 7 8 15 8 8 0 15 5 15 10 0 14 -28 12 -33 -2 -2 -7 -8 -9 -13 -5 -13 12 18
28 43 22 17 -4 29 4 50 31 15 20 45 55 65 78 21 22 41 50 44 61 4 10 13 25 21
33 9 9 11 25 8 46 -6 31 21 136 40 154 11 10 0 -45 -19 -97 -22 -57 -15 -75 9
-25 35 73 57 217 53 343 l-3 86 -55 1 c-30 1 -73 0 -95 -2z m113 -22 c-28 -3
-28 -4 -28 -82 0 -43 -4 -82 -10 -85 -6 -4 -10 23 -10 74 l0 81 -42 2 c-24 1
-59 1 -78 0 -22 -1 -29 1 -18 6 10 4 61 7 115 7 54 1 86 -1 71 -3z m22 -108
c0 -52 -5 -105 -11 -116 -8 -15 -8 -22 1 -28 8 -5 8 -10 2 -14 -5 -3 -12 1
-16 9 -8 22 6 245 16 245 4 0 8 -43 8 -96z m-240 72 c13 -16 10 -61 -3 -61 -5
0 -15 17 -22 38 -10 29 -10 37 0 37 7 0 18 -7 25 -14z m-72 -88 c-5 -14 -56
-33 -65 -24 -3 3 0 12 7 21 15 18 64 21 58 3z m82 -33 c0 -9 -9 -15 -21 -15
-18 0 -19 2 -9 15 7 8 16 15 21 15 5 0 9 -7 9 -15z m184 -132 c-8 -45 -19 -62
-21 -30 -2 35 6 67 18 67 6 0 7 -14 3 -37z m-504 -33 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m0 -80 c0 -16 -4 -30 -10
-30 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 -13 10 -30z m-40 -70 c0 -21
-24 -28 -35 -10 -8 14 3 30 21 30 8 0 14 -9 14 -20z m418 -32 c-3 -7 -11 -13
-18 -13 -7 0 -15 6 -17 13 -3 7 4 12 17 12 13 0 20 -5 18 -12z m58 -3 c8 -19
-14 -47 -29 -38 -7 4 -8 0 -3 -13 4 -10 4 -15 0 -12 -4 4 -20 -12 -37 -35 -41
-58 -50 -66 -38 -35 13 35 81 134 81 119 0 -7 5 -9 10 -6 8 5 7 11 -1 21 -9
10 -9 14 0 14 6 0 14 -7 17 -15z m-610 -110 c2 -38 4 -98 4 -132 0 -59 1 -62
28 -66 21 -4 19 -4 -7 -3 -28 1 -35 5 -36 23 -1 13 -5 26 -9 30 -4 4 -6 -9 -4
-30 2 -28 6 -37 21 -37 10 0 16 -4 13 -9 -3 -5 -15 -7 -26 -4 -19 5 -20 14
-20 137 0 72 3 149 7 170 6 37 7 38 16 15 5 -13 11 -55 13 -94z m11 63 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m505 -3 c3 -14 2 -25 -3 -25 -4
0 -9 -8 -11 -17 -3 -10 -5 1 -6 25 -1 46 13 57 20 17z m-222 -105 c0 -5 -13
-10 -30 -10 -16 0 -30 5 -30 10 0 6 14 10 30 10 17 0 30 -4 30 -10z m187 4
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-127 -24 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-142 -27 c-2 -10
-10 -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m206
-10 c4 -10 4 -14 0 -10 -4 4 -13 2 -21 -5 -7 -6 -17 -8 -21 -4 -4 4 2 14 12
22 24 17 23 17 30 -3z m-89 -13 c-3 -5 3 -10 15 -10 12 0 19 -4 15 -9 -3 -5
-21 -7 -40 -4 -23 4 -35 2 -35 -6 0 -6 6 -11 12 -11 7 0 2 -7 -10 -16 -12 -8
-33 -14 -47 -12 -23 3 -24 5 -12 28 7 14 23 26 37 28 14 2 29 8 35 13 12 12
38 12 30 -1z m-202 -87 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m72 -3 c-3 -5 -18 -10 -33 -9 -24 0 -25 1 -7 9 28 12 47 12 40 0z m-87 -17
c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z"/>
<path d="M3641 7335 c1 -48 1 -49 10 -17 6 21 6 39 0 50 -8 13 -10 5 -10 -33z"/>
<path d="M3647 7236 c-8 -29 -5 -41 10 -35 5 2 6 18 3 34 l-6 30 -7 -29z"/>
<path d="M9330 7933 c-14 -9 -27 -18 -29 -19 -2 -2 7 -19 19 -39 12 -19 20
-41 17 -49 -3 -7 -27 -26 -54 -41 -91 -54 -203 -130 -203 -139 0 -5 9 -24 20
-42 l21 -33 44 27 c24 15 77 47 117 71 40 25 79 51 86 58 22 21 36 15 60 -27
23 -40 23 -40 52 -25 16 8 31 18 33 22 5 8 -139 246 -151 251 -4 1 -19 -5 -32
-15z"/>
<path d="M5954 7896 c-63 -13 -195 -56 -204 -67 -3 -3 -21 -14 -40 -25 -83
-46 -200 -180 -250 -289 -28 -61 -60 -187 -60 -237 l0 -36 71 -22 c55 -17 96
-22 189 -23 65 0 131 5 147 10 15 6 48 17 73 24 40 12 107 44 120 59 3 3 22
14 43 24 39 20 45 31 25 40 -7 3 -4 4 7 1 15 -4 33 8 66 43 66 67 94 112 71
112 -5 0 -14 -12 -21 -27 -9 -19 -15 -23 -20 -14 -6 10 -10 9 -15 -4 -8 -23
-8 -27 4 -20 6 3 10 1 10 -4 0 -6 -4 -11 -9 -11 -5 0 -22 -13 -37 -28 -16 -16
-48 -34 -71 -41 -40 -11 -53 -24 -35 -34 4 -2 2 -2 -5 -1 -6 2 -14 -3 -17 -11
-3 -8 -13 -11 -27 -8 -22 6 -22 6 6 29 15 12 29 33 31 46 1 12 3 26 3 30 1 4
17 0 36 -9 34 -16 36 -16 60 7 29 27 25 42 -4 16 -19 -17 -19 -17 -18 15 2 26
7 35 27 40 14 4 21 7 16 8 -5 1 8 17 28 36 28 26 36 40 31 55 -5 14 -2 20 10
20 11 0 16 -8 15 -22 -1 -13 -1 -26 0 -30 1 -5 3 -13 4 -20 2 -23 23 -18 35 9
41 88 91 298 75 313 -9 8 -212 49 -274 55 -19 2 -62 -2 -96 -9z m158 -18 c7
-4 -14 -5 -47 -2 -33 4 -62 9 -65 11 -9 8 100 -1 112 -9z m-172 -9 c-17 -6 -3
-7 42 -4 79 6 154 -3 140 -17 -6 -6 -17 -6 -30 1 -27 14 -72 14 -72 0 0 -8
-15 -9 -47 -4 -61 10 -70 10 -118 1 -54 -10 -40 2 21 18 58 16 104 19 64 5z
m241 -7 c-7 -2 -18 1 -23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z m59 -12 c0 -5
-15 -10 -32 -10 -25 0 -29 3 -18 10 20 13 50 13 50 0z m-413 -37 c-9 -9 -19
-14 -23 -11 -10 10 6 28 24 28 15 0 15 -1 -1 -17z m470 -42 c-4 -53 -25 -159
-34 -171 -2 -2 -8 2 -13 10 -13 20 -13 90 -1 90 6 0 12 15 14 33 3 28 4 26 7
-13 l3 -45 6 40 c5 26 2 45 -6 55 -15 19 -6 60 13 60 11 0 14 -13 11 -59z
m-377 39 c0 -6 34 -10 82 -10 89 0 99 -17 13 -21 -27 -1 -56 -7 -63 -13 -9 -6
-16 -6 -20 0 -4 6 -15 13 -24 17 -22 8 -24 37 -3 37 8 0 15 -4 15 -10z m-160
-20 c0 -5 -6 -9 -12 -7 -7 1 -12 -3 -11 -9 1 -7 -6 -10 -17 -7 -25 7 -26 20
-2 26 30 8 42 7 42 -3z m470 0 c0 -5 -12 -10 -26 -10 -14 0 -23 4 -19 10 3 6
15 10 26 10 10 0 19 -4 19 -10z m-390 -20 c0 -5 -11 -10 -25 -10 -14 0 -25 5
-25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z m-160 -45 c0 -3 -9 -14 -20 -25
-11 -11 -22 -20 -25 -20 -7 0 -6 36 2 43 7 8 43 9 43 2z m-70 -55 c0 -11 -4
-20 -9 -20 -5 0 -12 -12 -15 -26 -5 -20 -11 -24 -29 -19 -17 5 -19 4 -7 -4 8
-6 11 -11 6 -11 -6 0 -5 -5 2 -12 8 -8 15 -8 28 3 16 13 17 11 10 -26 -11 -62
-17 -76 -29 -72 -7 2 -16 19 -21 38 -6 19 -16 40 -23 47 -15 16 -18 36 -3 27
6 -3 10 0 10 8 0 22 27 41 33 25 5 -13 11 17 8 36 -2 9 18 26 32 26 4 0 7 -9
7 -20z m10 -58 c0 -5 -7 -9 -15 -9 -8 0 -12 4 -9 9 3 4 9 8 15 8 5 0 9 -4 9
-8z m-107 -122 c-6 -31 -18 -39 -26 -17 -3 8 -10 6 -21 -8 -8 -11 -16 -15 -16
-8 0 6 5 15 10 18 6 3 10 13 10 21 0 8 8 26 17 40 16 26 17 26 25 5 4 -12 5
-35 1 -51z m443 -65 c7 -18 -14 -47 -27 -39 -5 3 -9 16 -9 30 0 27 27 34 36 9z
m-516 -116 c-15 -27 -13 -29 28 -30 l37 -1 -30 -9 c-29 -8 -28 -8 8 -6 20 2
37 -1 37 -5 0 -4 -8 -9 -17 -9 -17 -1 -17 -2 0 -6 9 -2 17 -9 17 -14 0 -12
-43 -1 -48 12 -2 5 -14 9 -27 9 -29 0 -38 24 -25 69 5 18 9 36 8 40 -2 23 5
21 12 -3 6 -17 5 -36 0 -47z m620 31 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10
0 6 5 10 10 10 6 0 10 -4 10 -10z m-175 -70 c27 -6 28 -8 10 -14 -11 -4 -28
-12 -37 -17 -10 -5 -26 -9 -36 -9 -10 0 -23 -5 -30 -12 -9 -9 -13 -8 -17 3 -4
12 -24 13 -107 7 -57 -3 -109 -2 -118 2 -9 6 27 10 98 10 61 0 112 4 112 9 0
9 55 29 80 29 8 0 29 -4 45 -8z m60 0 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10
3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
<path d="M6252 7660 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5495 7519 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M5804 7254 c-18 -15 -18 -15 6 -10 14 3 31 6 38 6 6 0 12 5 12 10 0
15 -34 12 -56 -6z"/>
<path d="M2105 7880 c-13 -15 -16 -23 -8 -31 6 -6 9 -18 6 -25 -3 -9 2 -14 16
-14 19 0 20 -2 9 -22 -16 -27 -48 -61 -48 -50 0 4 6 14 12 20 10 10 10 15 -2
22 -21 13 -32 12 -27 -2 2 -7 -15 -31 -38 -53 -41 -39 -48 -65 -18 -65 8 0 13
-6 10 -12 -2 -7 -10 -12 -16 -10 -7 1 -20 -5 -29 -15 -13 -13 -14 -17 -4 -21
10 -3 7 -12 -11 -31 -14 -14 -27 -35 -29 -46 -2 -11 -6 -15 -10 -10 -3 6 2 27
12 47 15 32 16 38 3 42 -21 9 -25 7 -17 -6 4 -7 3 -8 -4 -4 -16 10 -3 36 18
36 23 0 44 26 37 44 -3 8 -3 16 1 18 11 5 45 69 39 74 -5 6 -62 -79 -59 -89 1
-4 -5 -10 -14 -14 -8 -3 -47 -53 -86 -112 -39 -58 -75 -107 -80 -109 -4 -2 -8
-8 -8 -14 0 -6 -11 -26 -25 -44 -14 -18 -25 -36 -25 -39 0 -4 -9 -20 -21 -36
-20 -29 -20 -31 -4 -49 15 -16 15 -22 5 -35 -7 -8 -9 -15 -4 -15 4 0 3 -5 -4
-12 -7 -7 -12 -23 -12 -37 -1 -24 -2 -24 -14 -9 -7 10 -16 15 -19 11 -4 -3 -7
1 -7 10 0 21 31 58 42 51 10 -7 10 7 -1 25 -11 17 -54 -55 -146 -245 -108
-224 -225 -510 -225 -549 0 -8 -6 -29 -14 -47 -14 -33 -33 -95 -56 -178 -7
-25 -16 -54 -20 -65 -5 -11 -14 -47 -20 -80 -7 -33 -16 -78 -20 -100 -38 -189
-56 -303 -66 -415 -3 -36 -9 -96 -14 -135 -26 -203 7 -1011 40 -990 4 3 13 -9
19 -27 14 -41 14 -43 -6 -27 -15 13 -16 11 -9 -11 4 -14 11 -50 16 -80 4 -30
15 -84 24 -121 9 -36 17 -75 19 -87 2 -12 6 -24 9 -28 4 -3 4 -12 2 -19 -3 -7
-1 -16 5 -20 6 -3 11 -16 11 -28 0 -40 111 -393 155 -492 7 -16 24 -57 38 -90
62 -149 255 -527 278 -545 3 -3 21 -32 40 -65 41 -74 52 -90 164 -243 50 -68
95 -131 100 -140 6 -9 15 -20 21 -24 6 -4 23 -25 39 -47 22 -33 32 -40 50 -35
13 3 26 1 29 -5 12 -19 28 -12 21 8 -7 23 10 38 19 15 4 -10 2 -13 -6 -8 -7 4
-8 3 -4 -4 4 -6 13 -9 21 -5 8 3 15 0 15 -6 0 -6 -4 -11 -9 -11 -4 0 -6 -11
-3 -25 3 -16 0 -25 -9 -25 -7 0 -10 -2 -7 -5 10 -11 59 15 53 27 -4 7 3 2 14
-11 22 -24 19 -50 -3 -32 -7 6 -25 9 -42 8 -27 -2 -29 0 -26 28 2 22 0 26 -7
16 -8 -12 -13 -11 -29 10 -11 13 -23 21 -27 17 -8 -7 52 -83 99 -127 29 -27
37 -10 9 21 -15 17 -15 18 0 18 10 0 17 -4 17 -9 0 -6 9 -8 21 -6 24 5 72 -23
63 -36 -8 -14 -24 -10 -24 6 0 25 -47 19 -54 -7 -4 -19 15 -43 115 -143 112
-111 122 -118 135 -101 12 17 51 16 41 -1 -5 -11 25 -37 47 -41 14 -3 16 1 11
17 -8 27 6 27 23 1 8 -13 8 -20 1 -20 -5 0 -7 -5 -3 -11 3 -6 15 -8 26 -6 16
4 17 3 7 -9 -6 -8 -8 -14 -3 -14 5 0 4 -4 -3 -8 -7 -4 -9 -13 -4 -21 6 -10 11
-9 24 3 8 9 24 16 34 16 17 -1 17 -1 1 -11 -28 -16 -21 -39 12 -45 16 -4 27
-10 25 -15 -7 -10 -34 -1 -58 19 -14 12 -20 13 -30 3 -9 -10 -6 -17 18 -34 17
-12 40 -32 53 -44 13 -13 25 -23 28 -23 3 0 38 -24 77 -53 119 -88 137 -99
137 -79 0 5 -12 14 -27 21 -22 10 -24 14 -12 22 10 8 8 9 -11 5 -19 -3 -30 2
-43 20 -21 28 -16 44 7 25 19 -16 31 15 15 38 -6 9 -1 5 12 -9 13 -14 38 -31
54 -39 17 -7 25 -16 18 -18 -6 -2 -23 3 -37 12 -27 18 -46 13 -46 -13 0 -12 3
-13 11 -5 8 8 20 9 37 2 13 -5 33 -7 43 -4 14 5 17 2 13 -9 -5 -12 0 -16 19
-16 25 0 83 -38 73 -48 -3 -2 -11 -1 -18 5 -8 6 -30 5 -62 -2 l-49 -11 63 -39
c124 -80 255 -157 358 -211 48 -26 102 -55 119 -66 22 -13 42 -18 60 -14 16 3
35 1 43 -4 11 -8 8 -9 -12 -4 -44 10 -33 -5 20 -27 26 -11 67 -29 92 -41 46
-22 59 -27 120 -48 19 -6 48 -19 63 -27 15 -8 35 -12 43 -9 8 3 13 2 10 -2 -3
-5 21 -17 52 -27 31 -10 71 -24 87 -30 46 -18 87 -30 245 -69 80 -20 161 -41
180 -46 70 -18 289 -52 460 -71 223 -24 637 -26 877 -4 93 9 175 18 184 20 8
3 67 14 130 24 176 30 272 52 524 122 22 6 49 15 60 19 11 5 63 23 115 41 52
18 97 35 100 39 3 3 35 15 71 28 208 69 646 306 859 463 19 14 70 51 112 82
42 31 79 57 82 57 3 0 16 10 29 23 13 12 48 42 77 67 210 175 406 373 598 606
70 85 112 146 105 157 -3 5 -1 7 4 4 6 -4 23 12 38 36 16 23 34 46 42 50 7 4
13 12 13 18 0 5 21 42 48 82 63 97 70 108 82 135 5 12 13 22 17 22 5 0 13 10
19 23 5 12 32 63 59 112 94 175 133 260 120 263 -7 2 -15 12 -18 23 -3 10 -1
17 3 14 4 -3 13 9 19 27 6 17 14 29 17 25 3 -3 1 -14 -5 -25 -13 -24 -14 -42
-3 -42 9 0 32 44 68 135 15 39 31 75 35 80 4 6 14 33 22 60 9 28 25 74 36 104
17 44 18 57 8 70 -10 12 -9 14 4 9 12 -5 21 13 44 90 32 114 42 165 26 142 -6
-8 -11 -27 -11 -42 0 -16 -3 -28 -8 -28 -4 0 -16 -21 -27 -46 -12 -27 -31 -51
-47 -60 -30 -16 -37 -5 -13 20 10 9 13 24 10 38 -3 13 2 50 11 83 9 33 19 75
21 93 3 18 9 35 14 38 5 3 9 13 9 22 0 9 8 26 16 37 15 19 16 17 12 -27 -3
-40 0 -48 14 -48 24 0 23 -17 -2 -30 -12 -6 -20 -21 -20 -35 l0 -24 24 24 c16
15 29 45 35 77 6 29 13 64 16 78 3 14 12 62 20 108 8 46 17 87 20 92 6 10 24
139 30 215 3 33 10 105 16 160 23 209 9 692 -27 960 -11 85 -86 453 -104 510
-65 212 -85 272 -122 375 -38 107 -55 148 -112 275 -13 28 -31 69 -41 93 -14
32 -24 42 -42 42 -16 0 -23 6 -23 20 0 11 -5 20 -11 20 -7 0 -9 -9 -6 -21 3
-12 0 -28 -7 -35 -11 -11 -19 -1 -45 57 -17 38 -31 76 -31 85 0 11 -5 14 -16
10 -12 -5 -15 -2 -9 11 16 40 16 50 -1 57 -9 3 -19 4 -22 1 -3 -3 0 -5 6 -5 7
0 12 -7 12 -16 0 -14 -3 -14 -15 -4 -8 7 -15 22 -15 35 0 12 -13 35 -30 51
-33 33 -38 55 -14 75 11 9 20 10 32 3 24 -16 26 -33 3 -27 -13 3 -21 1 -21 -6
0 -7 -4 -9 -10 -6 -5 3 -10 1 -10 -6 0 -6 8 -9 20 -6 15 4 20 0 20 -13 0 -10
7 -20 15 -24 8 -3 15 -12 15 -20 0 -7 7 -17 16 -20 23 -9 24 -8 17 11 -4 10
-2 14 4 10 6 -4 8 -26 5 -53 -4 -35 -2 -45 7 -40 8 5 10 19 6 37 -4 18 -3 29
4 29 6 0 11 -8 11 -18 0 -10 9 -28 20 -40 11 -12 20 -28 20 -36 0 -8 5 -18 10
-21 13 -8 13 -35 0 -35 -5 0 -12 17 -16 37 -6 30 -9 33 -14 18 -4 -13 -9 -15
-14 -7 -4 7 -4 17 -1 22 6 10 -8 14 -17 4 -8 -7 12 -70 35 -114 17 -33 18 -33
14 -7 -3 20 1 31 14 38 16 8 21 5 29 -16 5 -14 14 -25 20 -25 11 0 -99 221
-125 250 -7 8 -16 25 -19 38 -4 12 -12 22 -17 22 -5 0 -9 5 -9 11 0 15 -30 38
-49 39 -19 0 -40 -30 -41 -58 0 -13 10 -38 23 -55 59 -84 247 -467 301 -617
15 -41 34 -91 41 -110 8 -19 22 -59 31 -89 17 -52 17 -53 30 -30 16 30 17 22
3 -20 -9 -26 -8 -40 4 -69 25 -58 103 -393 126 -542 19 -121 32 -239 46 -425
25 -326 1 -707 -66 -1050 -18 -91 -28 -136 -61 -275 -33 -140 -153 -495 -189
-558 -11 -18 -19 -39 -19 -45 0 -13 -136 -296 -161 -337 -9 -14 -44 -72 -77
-130 -34 -58 -66 -112 -71 -120 -119 -179 -190 -281 -222 -318 -36 -42 -89
-110 -89 -115 0 -8 -134 -151 -274 -294 -77 -79 -133 -143 -124 -143 8 0 21 7
28 15 7 8 18 15 26 15 7 0 16 5 20 12 5 8 1 9 -12 4 -10 -4 13 25 51 64 47 48
71 66 73 55 5 -22 20 -19 27 6 4 11 9 18 11 16 7 -6 -18 -57 -27 -57 -4 0 -9
6 -12 13 -2 7 -8 8 -13 3 -5 -5 -4 -11 3 -13 8 -3 2 -13 -15 -28 -15 -13 -23
-27 -19 -31 4 -5 18 4 31 19 13 15 32 27 42 27 14 0 9 -7 -19 -27 -20 -14 -37
-29 -37 -33 0 -4 -11 -7 -25 -7 -26 0 -34 12 -12 20 6 2 9 8 5 12 -10 10 -41
-22 -33 -35 3 -5 13 -10 21 -10 8 0 14 -2 14 -4 0 -6 -82 -86 -88 -86 -4 0
-25 -18 -47 -39 -140 -138 -175 -164 -172 -132 0 11 6 17 14 14 7 -3 13 2 13
10 0 9 14 25 30 37 36 26 41 54 8 45 -18 -5 -20 -4 -8 4 8 6 23 11 32 11 10 0
18 9 20 22 2 15 11 24 25 26 15 2 20 8 17 20 -5 15 -4 15 6 -1 13 -20 5 -38
-14 -31 -8 3 -17 1 -21 -5 -4 -7 4 -11 20 -11 14 0 28 6 31 14 3 8 16 17 30
20 13 4 24 11 24 18 0 6 8 17 18 24 16 12 16 13 0 14 -9 0 -25 -11 -36 -25
-11 -14 -26 -25 -33 -25 -7 0 -10 3 -6 6 3 3 0 12 -6 19 -10 10 -25 1 -78 -46
-37 -33 -69 -59 -72 -59 -3 0 -18 -13 -34 -28 -15 -15 -39 -35 -52 -44 -13 -9
-20 -21 -17 -27 4 -6 22 4 44 25 20 19 38 30 40 24 2 -6 -13 -26 -33 -44 -32
-29 -36 -36 -26 -54 8 -15 7 -21 -3 -25 -18 -7 -42 18 -26 28 12 8 13 16 1 34
-7 11 -22 5 -73 -32 -34 -26 -72 -54 -83 -63 -11 -9 -31 -21 -45 -27 -14 -6
-27 -14 -30 -18 -12 -15 -225 -149 -237 -149 -7 0 -13 -4 -13 -8 0 -5 -27 -22
-59 -38 -59 -29 -90 -62 -77 -83 5 -8 13 -8 27 0 12 6 23 8 27 5 12 -12 -17
-28 -52 -29 -20 0 -31 -4 -25 -8 6 -3 -1 -9 -16 -13 -30 -8 -61 -33 -51 -43 3
-3 14 1 23 9 23 20 78 48 95 48 13 0 2 -6 -98 -60 -43 -23 -47 -23 -59 -7 -13
19 -45 23 -45 7 0 -6 -6 -6 -14 2 -11 10 -6 16 28 31 22 10 49 16 59 13 14 -3
16 -2 7 9 -9 10 -6 15 12 21 23 7 77 44 64 44 -4 0 -33 -14 -66 -30 -33 -17
-66 -30 -75 -30 -8 0 -15 -4 -15 -8 0 -5 -23 -18 -52 -31 -31 -14 -47 -25 -40
-30 6 -4 -4 -7 -22 -7 -19 0 -54 -9 -78 -21 -72 -34 -118 -53 -129 -53 -6 0
-25 -6 -42 -14 -35 -16 -186 -66 -200 -66 -5 0 -23 -6 -40 -14 -18 -8 -52 -19
-77 -25 -25 -5 -72 -16 -105 -25 -33 -8 -70 -15 -82 -16 -12 0 -34 -5 -50 -12
-15 -6 -37 -13 -48 -14 -11 -1 -76 -11 -145 -22 -69 -11 -181 -25 -250 -32
-69 -6 -141 -14 -160 -17 -19 -3 -147 -5 -285 -5 -254 1 -493 21 -712 58 -54
9 -107 17 -118 17 -11 1 -22 4 -25 8 -3 5 -24 11 -47 13 -40 5 -231 53 -245
62 -3 2 -9 -2 -12 -8 -3 -10 -8 -9 -18 4 -8 9 -32 21 -53 27 -66 18 -191 59
-211 69 -11 5 -35 15 -54 21 -75 26 -125 46 -130 52 -3 4 -16 10 -30 14 -37
11 -134 58 -138 67 -2 4 -10 8 -18 8 -8 0 -40 13 -72 29 -37 19 -67 27 -84 24
-26 -5 -53 17 -29 25 6 2 -14 17 -46 34 -32 16 -60 34 -61 39 -2 5 -9 9 -15 9
-11 0 -208 123 -230 144 -7 6 -23 17 -37 24 -14 6 -45 28 -70 47 -25 19 -52
38 -60 42 -8 4 -24 16 -35 26 -11 11 -44 37 -72 59 -29 22 -53 44 -53 49 0 5
-5 9 -11 9 -10 0 -106 80 -181 150 -70 65 -254 251 -252 253 2 2 -27 35 -64
75 -71 73 -66 71 -156 73 -18 0 -18 0 0 14 10 7 23 11 28 7 6 -3 21 -7 33 -8
23 -3 23 -1 -9 39 -18 23 -38 48 -44 54 -7 7 -42 55 -80 108 -37 52 -71 95
-76 95 -4 0 -8 4 -8 9 0 4 -25 48 -55 97 -30 49 -80 134 -112 189 -31 55 -61
102 -65 103 -4 2 -8 7 -8 12 0 15 -63 145 -71 148 -5 2 -9 8 -9 14 0 10 -52
137 -74 178 -7 14 -26 61 -41 105 -16 44 -32 85 -36 90 -4 6 -21 56 -38 113
-17 57 -34 108 -37 114 -8 12 -17 47 -58 218 -45 184 -80 390 -98 575 -17 170
-17 673 0 840 28 275 82 553 157 803 13 45 22 90 18 100 -3 9 -2 14 2 10 9 -8
45 64 45 88 0 8 4 22 10 32 5 9 24 55 41 102 40 107 83 200 166 365 98 193
106 207 131 242 12 17 22 34 22 37 0 3 27 46 60 95 33 50 60 98 60 107 0 10 3
15 6 11 4 -3 38 37 76 89 38 51 74 94 79 94 5 0 9 6 9 13 0 7 20 35 43 62 24
28 49 59 54 70 11 21 1 65 -16 65 -5 0 -16 2 -23 5 -8 3 -23 -4 -33 -15z m51
-14 c18 -19 18 -32 -2 -40 -22 -8 -40 4 -23 15 11 7 10 9 -3 9 -29 0 -21 22
10 29 2 1 10 -5 18 -13z m-256 -280 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0
6 5 11 10 11 6 0 10 -2 10 -4z m8 -38 c-7 -13 -11 -29 -10 -35 5 -25 -17 -51
-38 -46 -11 3 -23 10 -26 15 -10 16 17 57 32 51 10 -4 12 0 8 11 -4 12 -2 15
9 10 8 -3 18 -1 22 5 12 21 16 11 3 -11z m-78 -83 c0 -8 9 -15 20 -15 23 0 25
-8 6 -33 -12 -16 -14 -16 -20 -2 -3 9 -2 13 4 10 6 -3 10 -1 10 4 0 6 -2 11
-4 11 -2 0 -12 3 -21 7 -15 5 -16 3 -7 -14 13 -23 4 -65 -12 -60 -13 5 -15 41
-3 80 10 29 27 37 27 12z m-55 -105 c-4 -11 -2 -20 4 -20 6 0 11 5 11 10 0 6
5 10 10 10 6 0 4 -13 -5 -30 -16 -31 -39 -33 -28 -3 4 11 3 14 -5 9 -15 -9
-15 -1 -2 25 14 25 23 25 15 -1z m-40 -20 c-19 -38 -19 -46 2 -29 15 12 16 12
11 -7 -3 -12 -2 -28 3 -35 5 -9 1 -20 -12 -32 -10 -10 -19 -26 -19 -36 0 -10
-7 -24 -15 -31 -8 -7 -15 -19 -15 -27 0 -7 -9 -23 -20 -35 -11 -12 -20 -33
-21 -47 0 -14 -3 -20 -6 -13 -2 6 -9 12 -15 12 -6 0 -8 -7 -5 -15 4 -8 -1 -27
-9 -42 l-16 -28 6 32 c4 25 2 31 -9 26 -21 -8 -18 3 7 27 12 11 17 20 11 20
-6 0 -14 7 -17 16 -3 8 -2 12 4 9 6 -3 10 -1 10 6 0 6 8 9 20 6 11 -3 18 -1
14 4 -3 5 1 7 8 4 14 -6 58 68 58 96 0 9 7 22 15 29 18 15 11 29 -13 25 -9 -1
-12 0 -6 2 7 2 10 12 7 23 -3 10 3 30 12 44 23 35 29 33 10 -4z m35 -60 c0 -5
-2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m7344 -6
c11 -28 6 -38 -9 -18 -8 10 -15 22 -15 27 0 13 18 7 24 -9z m-7484 -134 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-59 -105
c-11 -22 -20 -33 -21 -24 0 21 33 86 37 73 2 -5 -6 -28 -16 -49z m7633 20 c3
-8 1 -15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-7612 -68
c4 -20 0 -23 -26 -25 -17 -2 -34 -8 -38 -15 -4 -6 -10 -8 -13 -5 -12 11 18 35
37 30 13 -3 18 1 18 17 0 28 17 27 22 -2z m7678 -42 c0 -14 5 -25 11 -25 11 0
48 -40 49 -52 0 -16 -19 -7 -25 11 -3 11 -12 18 -20 15 -8 -4 -17 7 -25 30 -6
19 -16 37 -23 40 -7 2 -2 5 11 5 17 1 22 -5 22 -24z m17 3 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-7708 -21 c-8 -13 -13 -16 -18 -8 -5 7
-12 2 -20 -16 -9 -19 -15 -23 -20 -14 -6 10 -12 7 -24 -10 -23 -31 -33 -17
-11 16 9 14 23 24 29 21 7 -2 15 4 18 13 3 12 14 19 30 19 23 0 25 -3 16 -21z
m7665 7 c3 -9 6 -23 6 -32 -1 -12 -5 -10 -15 8 -8 14 -14 28 -15 33 0 13 18 7
24 -9z m-7689 -68 c-16 -24 -35 -35 -35 -19 0 10 43 54 48 49 2 -2 -4 -16 -13
-30z m7716 2 c0 -10 2 -10 6 0 8 21 21 13 28 -19 5 -21 10 -27 15 -19 11 17
30 3 30 -21 0 -11 7 -33 15 -49 10 -19 12 -36 7 -51 -11 -29 -3 -77 9 -57 7
10 9 10 9 1 0 -7 5 -13 10 -13 7 0 7 6 0 19 -5 11 -10 30 -9 43 0 17 4 14 15
-16 9 -26 11 -43 4 -51 -7 -8 -7 -22 0 -42 8 -21 7 -38 -1 -59 -10 -27 -12
-27 -19 -9 -4 11 -4 21 1 23 9 4 -18 52 -35 63 -5 4 -6 14 -3 24 4 9 3 14 -2
11 -5 -3 -12 5 -15 18 -3 13 -9 32 -13 42 -4 12 -3 15 5 10 7 -4 12 -1 12 8 0
9 -6 13 -14 10 -9 -3 -15 4 -18 20 -6 30 0 33 18 9 12 -16 13 -14 11 14 -1 22
-7 32 -16 30 -8 -1 -21 9 -29 23 l-14 25 6 -27 c4 -16 2 -28 -4 -28 -5 0 -10
11 -10 24 0 14 -4 28 -10 31 -5 3 -10 10 -10 16 0 14 20 11 21 -3z m-7771 -52
c-11 -14 -50 -10 -50 5 0 13 18 20 25 9 3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6
-3 16 2 23 5 9 9 7 11 -6 2 -10 -1 -24 -8 -31z m9 -32 c2 -2 -3 -12 -11 -21
-9 -10 -18 -25 -21 -33 -3 -8 -6 -1 -6 16 -1 16 -7 36 -13 43 -11 11 -8 12 17
6 16 -3 32 -8 34 -11z m-59 -40 c0 -8 -4 -12 -10 -9 -6 4 -10 -6 -10 -22 1
-26 2 -27 14 -10 14 18 14 18 20 -1 4 -10 0 -36 -8 -58 -8 -21 -15 -33 -15
-26 -1 8 -9 10 -25 6 -16 -4 -25 -1 -29 10 -4 10 -1 13 7 10 8 -3 16 4 20 18
8 33 7 40 -4 33 -5 -3 -10 1 -10 9 0 9 4 16 9 16 5 0 13 9 16 20 8 24 25 27
25 4z m-58 -168 c15 10 27 -16 18 -40 -4 -12 -13 -41 -20 -64 -12 -48 -19 -47
-36 1 l-12 32 -7 -29 c-6 -20 -4 -31 3 -34 7 -2 12 -13 12 -24 0 -17 -3 -18
-15 -8 -13 11 -15 8 -15 -14 0 -14 -4 -26 -10 -26 -18 0 -11 42 26 140 7 19
13 27 13 18 1 -10 5 -18 10 -18 5 0 11 -10 14 -22 3 -22 4 -22 12 -4 6 13 5
21 -3 24 -7 2 -12 22 -11 45 0 23 3 36 6 29 2 -6 9 -9 15 -6z m-35 -18 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m8104 -28 c10 -45 6 -62 -15
-54 -11 4 -11 11 -2 36 8 24 8 32 -1 35 -7 2 -9 11 -6 19 8 21 13 12 24 -36z
m-35 -31 c-4 -26 -3 -38 4 -34 6 3 10 -3 10 -15 0 -26 -18 -17 -30 16 -6 16
-6 33 2 49 16 36 21 31 14 -16z m56 -45 c-3 -15 1 -29 8 -34 7 -5 14 -20 16
-36 2 -15 8 -30 13 -34 6 -3 15 -26 20 -51 5 -24 12 -53 16 -64 l7 -20 -14 20
c-7 11 -18 36 -25 55 l-11 35 -1 -30 c-1 -16 -1 -47 -1 -67 1 -44 -4 -47 -21
-15 -16 28 -28 107 -17 107 4 0 8 -11 8 -24 0 -14 5 -28 10 -31 17 -10 12 68
-6 93 -19 28 -25 69 -13 99 11 31 17 29 11 -3z m-8192 -154 c0 -13 -1 -13 -10
0 -5 8 -10 22 -10 30 0 13 1 13 10 0 5 -8 10 -22 10 -30z m50 16 c0 -7 -3 -29
-7 -47 l-8 -34 -6 45 c-6 43 -6 43 -7 -17 -1 -39 -6 -63 -13 -63 -6 0 -8 -7
-5 -16 3 -8 3 -34 0 -56 -6 -32 -9 -37 -16 -23 -6 9 -7 32 -4 50 4 19 2 36 -4
40 -5 3 -10 27 -10 52 0 48 14 53 15 6 0 -46 21 -35 23 13 1 25 4 50 7 55 9
14 35 11 35 -5z m-76 -155 c-3 -3 -9 1 -13 8 -7 11 -10 11 -15 0 -3 -8 -4 -3
-1 11 2 14 5 34 5 45 1 12 7 4 15 -20 8 -21 12 -42 9 -44z m8291 43 c10 4 14
-5 13 -35 0 -26 -5 -39 -14 -39 -8 0 -14 8 -14 19 0 10 -2 31 -4 47 -2 16 -1
23 1 16 3 -8 11 -11 18 -8z m-32 -54 c-3 -11 0 -20 6 -20 6 0 11 -17 11 -39 0
-22 5 -43 10 -46 6 -3 10 -17 10 -30 0 -13 -4 -27 -10 -30 -6 -4 -10 6 -10 23
0 16 -7 49 -15 72 -17 47 -20 104 -6 96 5 -3 7 -15 4 -26z m-8270 -126 c3 -18
1 -47 -3 -65 -8 -34 -8 -34 -7 9 0 23 -5 48 -11 54 -10 10 -11 2 -8 -32 2 -30
1 -35 -3 -15 -17 80 -40 -69 -34 -222 3 -82 1 -128 -7 -140 -9 -14 -10 -3 -5
50 3 39 2 67 -4 67 -5 0 -11 -6 -14 -12 -2 -7 -6 1 -9 18 -3 24 -1 31 9 27 10
-4 13 6 12 38 l-2 44 -7 -35 c-6 -27 -8 -30 -9 -10 0 14 9 61 20 105 12 44 21
88 21 98 -1 9 3 15 9 12 5 -4 9 3 9 14 0 12 7 21 15 21 9 0 12 6 9 18 -5 14
-4 14 4 2 6 -8 12 -29 15 -46z m8357 -1 c1 -5 5 -28 10 -53 11 -58 2 -123 -16
-116 -9 4 -14 -2 -14 -17 0 -18 2 -19 10 -7 7 11 10 3 11 -30 2 -37 3 -39 6
-12 3 19 9 30 15 26 7 -4 8 5 3 27 -4 25 -3 30 4 19 13 -19 14 -83 2 -75 -6 3
-14 -14 -19 -37 -9 -38 -10 -40 -12 -13 -1 27 -2 28 -11 10 -8 -17 -8 -15 -4
8 3 15 2 27 -3 27 -4 0 -13 28 -19 63 -7 34 -15 73 -18 87 -4 21 -4 22 4 5 7
-16 10 -12 13 25 4 38 5 34 8 -25 2 -41 5 -57 8 -40 4 26 5 28 12 10 8 -17 9
-16 9 8 1 16 -3 26 -9 22 -13 -8 -13 21 0 45 7 13 7 21 0 25 -5 3 -10 10 -10
16 0 11 20 13 20 2z m-8373 -135 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2
-19 0 -25z m-11 -33 c3 -20 4 -39 0 -42 -3 -4 -6 5 -6 20 0 14 -4 28 -8 31 -4
2 -7 -39 -6 -92 l2 -97 -8 100 c-4 55 -5 103 -3 108 10 17 23 5 29 -28z m8430
-93 c-9 -14 12 -183 25 -198 17 -20 35 -544 21 -609 -10 -46 -10 -42 -10 60 3
269 -3 415 -15 415 -8 0 -8 3 1 9 15 9 7 81 -9 81 -18 0 -30 54 -30 141 0 82
4 109 17 109 3 0 3 -4 0 -8z m-8519 -74 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-16 -169 l-9 -34 -1 31 c-1 17 2 34 6 37 12 13 14 -1 4 -34z
m8541 -169 c0 -48 3 -64 7 -45 4 17 6 -13 6 -65 0 -52 -2 -81 -6 -65 -7 36
-16 -133 -10 -185 l5 -35 7 30 c4 17 4 1 1 -35 -7 -70 -7 -131 1 -195 5 -37 5
-37 -2 -5 l-8 35 -5 -35 c-3 -22 -6 -4 -8 50 l-4 85 -6 -85 c-3 -47 -4 31 -3
173 4 302 3 289 13 257 7 -22 8 -20 9 15 0 25 -2 35 -8 27 -13 -21 -21 10 -21
78 0 33 -5 71 -12 85 -9 20 -8 31 2 51 l14 25 14 -43 c7 -24 14 -77 14 -118z
m-8555 33 c-2 -16 -4 -3 -4 27 0 30 2 43 4 28 2 -16 2 -40 0 -55z m20 35 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m34 -23 c4 25 6 10 6 -40 0
-59 -2 -71 -7 -45 -5 22 -8 28 -10 15 -2 -14 -4 -12 -9 10 -3 16 -4 48 -1 70
l4 40 6 -45 6 -45 5 40z m-50 -124 c0 -77 15 -98 22 -28 2 23 4 5 5 -40 1 -48
6 -83 12 -83 6 0 10 -18 10 -40 0 -22 -4 -40 -10 -40 -5 0 -9 -17 -9 -37 l2
-38 6 35 c5 24 9 -4 14 -90 4 -69 5 -141 1 -160 -3 -20 -2 -30 3 -23 5 7 8 -8
9 -35 0 -26 4 -68 8 -94 6 -30 5 -48 -1 -48 -11 0 -44 187 -42 240 l2 35 7
-35 c6 -30 8 -26 9 28 1 49 -2 62 -14 62 -21 0 -26 -152 -10 -280 5 -35 4 -51
-2 -45 -9 9 -34 206 -31 245 0 8 -3 78 -7 156 -7 130 1 401 12 391 2 -3 4 -37
4 -76z m59 -47 c0 -13 -5 -24 -10 -24 -6 0 -9 13 -8 29 3 35 18 31 18 -5z
m8507 -166 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m21 -272
l-1 -91 -7 70 c-4 39 -10 79 -14 91 -6 15 -3 21 8 21 14 0 16 -15 14 -91z
m-28 -271 c-17 -45 -23 -22 -14 57 l8 73 8 -51 c6 -32 5 -60 -2 -79z m-53 53
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-8410 -120 c-2 -13 -4
-5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m-14 0 c3 -24 2 -47 -4 -52 -5
-6 -8 -35 -8 -65 1 -31 3 -48 6 -38 7 26 21 20 26 -10 2 -16 4 -5 4 22 1 28
-1 42 -4 32 -2 -9 -9 -15 -14 -12 -14 10 -10 58 8 80 14 18 15 18 9 1 -3 -10
-2 -24 3 -30 5 -6 14 -42 20 -81 14 -85 20 -137 14 -110 -8 37 -26 43 -21 8 3
-18 0 -33 -4 -33 -5 0 -8 18 -7 41 3 30 -1 43 -14 50 -14 8 -17 6 -17 -13 0
-19 2 -20 10 -8 7 11 10 4 10 -25 0 -22 -5 -47 -11 -55 -9 -13 -10 -13 -6 0 2
8 -2 35 -10 60 -14 44 -27 256 -17 272 12 18 22 6 27 -34z m8425 15 c-5 -23
-21 -20 -24 4 -3 17 0 21 12 16 8 -3 14 -12 12 -20z m52 -38 c0 -8 -9 -31 -21
-51 -11 -20 -18 -42 -16 -48 3 -7 -3 -54 -13 -106 -10 -52 -19 -88 -19 -80 -1
12 -3 12 -10 2 -5 -9 -10 1 -14 30 -3 24 -9 59 -12 78 l-5 35 14 -40 c9 -25
14 -32 15 -20 1 17 3 17 11 5 8 -12 10 -9 10 12 0 19 -4 26 -14 22 -10 -4 -16
6 -21 36 -9 52 1 66 11 15 l7 -38 13 39 c8 22 12 49 9 62 -6 26 11 31 18 5 4
-14 5 -13 6 4 0 12 6 25 11 28 6 4 8 11 5 16 -4 5 -1 9 4 9 6 0 11 -7 11 -15z
m-65 -73 c-3 -3 -5 0 -5 7 0 7 -4 9 -10 6 -6 -4 -10 9 -9 32 l0 38 15 -39 c8
-22 12 -42 9 -44z m-48 -169 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26
0 -35z m-8273 -127 c-8 -32 -20 -34 -27 -4 -8 27 1 38 13 18 8 -12 10 -12 10
4 0 11 2 17 5 14 4 -3 3 -17 -1 -32z m-43 -51 c-4 -15 -7 -12 -13 15 -4 19 -5
42 -1 50 8 20 21 -38 14 -65z m63 -1 c3 -9 4 -19 1 -24 -3 -4 2 -21 10 -36 8
-16 15 -40 15 -54 l-1 -25 -15 25 -15 25 5 -35 c2 -19 1 -44 -4 -55 l-9 -20 0
20 c-1 17 -3 18 -12 5 -8 -11 -9 -7 -4 18 4 19 3 32 -4 32 -14 0 -35 81 -23
89 5 3 12 13 15 21 4 8 4 -1 1 -20 -6 -35 -6 -35 11 -12 9 13 14 30 10 42 -7
24 10 27 19 4z m46 -154 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-4 -31 c3 -6 1 -15 -5 -20 -6 -5 -9 -6 -6 -3 3 4 0 13
-6 21 -8 9 -8 13 -1 13 6 0 14 -5 18 -11z m8104 -49 c0 -11 5 -20 10 -20 6 0
8 -11 4 -25 -4 -15 -2 -25 5 -25 16 0 14 -16 -4 -34 -8 -8 -15 -29 -15 -46 0
-20 -4 -29 -11 -24 -7 4 -9 21 -5 43 3 20 1 43 -5 51 -7 11 -8 7 -3 -14 4 -18
3 -27 -3 -23 -6 4 -15 -5 -22 -19 -15 -33 -14 -34 9 -34 11 0 20 -6 20 -14 0
-30 -13 -46 -36 -46 -21 0 -24 5 -24 34 0 19 5 38 10 41 6 3 10 18 10 33 0 15
6 36 14 47 7 11 16 37 20 58 7 39 26 51 26 17z m-8051 -83 c6 -6 11 -15 11
-21 0 -5 -9 1 -20 14 -11 13 -20 32 -20 44 -1 20 -1 20 -11 3 -14 -24 -1 -117
17 -124 16 -6 19 -33 4 -33 -5 0 -10 7 -10 16 0 8 -4 12 -10 9 -6 -4 -10 7
-10 26 0 18 -6 40 -14 48 -16 19 -38 82 -31 89 2 3 11 -10 19 -29 12 -27 15
-29 16 -12 0 12 8 29 17 39 17 17 18 16 24 -20 3 -20 11 -42 18 -49z m8088 31
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-8097 -75 c0 -8 5 -11
11 -7 16 9 29 -15 28 -51 l-1 -30 -7 30 c-4 21 -13 31 -29 33 -12 2 -22 9 -22
17 0 7 -3 22 -7 32 -6 16 -5 16 10 4 9 -7 17 -20 17 -28z m60 -136 c-10 -10
-12 -8 -7 6 4 10 3 16 -3 12 -5 -3 -7 -32 -5 -63 3 -33 1 -51 -4 -44 -4 7 -7
25 -5 40 2 17 1 20 -2 8 -5 -17 -8 -15 -20 12 -16 38 -9 69 11 52 10 -8 15 -6
21 12 l7 23 10 -23 c8 -17 7 -26 -3 -35z m7893 8 c-3 -9 -8 -14 -10 -11 -3 3
-2 9 2 15 9 16 15 13 8 -4z m57 -5 c8 13 10 13 10 -2 0 -9 -4 -20 -10 -23 -5
-3 -10 -14 -10 -24 0 -9 -6 -25 -13 -35 -12 -16 -13 -16 -13 3 0 11 5 17 10
14 5 -4 6 11 2 38 -4 31 -3 40 4 29 9 -13 11 -13 20 0z m-7910 -77 c0 -13 -5
-23 -10 -23 -16 0 -34 46 -26 67 7 17 8 17 22 -1 8 -11 14 -30 14 -43z m7860
29 c0 -11 -8 -18 -20 -18 -23 0 -24 1 -8 25 14 22 28 18 28 -7z m-7862 -150
c23 -43 42 -48 42 -11 0 24 12 24 27 0 10 -15 9 -18 -5 -17 -23 1 -28 -46 -8
-64 9 -7 15 -24 15 -39 -2 -24 -2 -25 -6 -3 -3 16 -10 22 -19 18 -8 -3 -13 -2
-10 2 4 8 -13 47 -54 122 -10 19 -19 44 -19 55 l1 20 7 -20 c3 -11 16 -39 29
-63z m47 50 c3 -8 3 -17 0 -20 -3 -3 -5 0 -5 6 0 7 -6 12 -14 12 -9 0 -12 -9
-9 -27 l4 -28 -15 25 c-9 15 -11 29 -5 36 13 15 37 13 44 -4z m7748 -37 c-15
-11 -15 -14 2 -42 14 -24 15 -34 6 -49 -10 -17 -11 -16 -5 7 3 15 1 30 -5 33
-7 5 -11 -3 -11 -20 0 -15 -8 -34 -18 -43 -25 -22 -46 -82 -32 -91 12 -8 -7
-60 -22 -60 -4 0 -8 -7 -8 -15 0 -14 -29 -70 -55 -108 -10 -14 -14 -15 -17 -5
-3 7 1 18 7 23 21 17 57 85 46 85 -6 0 -11 -4 -11 -10 0 -5 -4 -10 -10 -10
-19 0 -10 28 10 33 11 3 20 12 20 21 0 9 7 16 15 16 8 0 15 7 15 15 0 9 -6 12
-15 9 -13 -5 -13 -3 0 22 8 16 15 39 15 52 0 13 6 22 15 22 14 0 35 29 35 49
0 6 -4 11 -10 11 -5 0 -10 -9 -10 -20 0 -11 -6 -20 -14 -20 -11 0 -6 17 17 63
17 34 30 67 29 72 -1 6 4 1 13 -9 13 -17 13 -20 -2 -31z m-7713 -80 c10 -12
10 -15 -4 -15 -9 0 -16 7 -16 15 0 8 2 15 4 15 2 0 9 -7 16 -15z m55 -90 c14
-31 25 -59 25 -63 0 -4 11 -17 24 -29 13 -13 26 -33 29 -45 6 -21 5 -22 -8 -5
-13 18 -14 18 -24 0 -13 -23 -14 -8 -1 18 8 15 7 19 -3 19 -17 0 -49 44 -46
63 2 11 -4 30 -25 77 -3 8 -4 1 -1 -17 2 -18 0 -35 -5 -38 -6 -3 -10 5 -10 19
0 13 -3 31 -6 40 -4 10 -1 16 10 16 9 0 26 -22 41 -55z m7570 12 c-4 -15 -13
-31 -21 -38 -8 -6 -14 -22 -14 -35 0 -13 -4 -24 -9 -24 -11 0 -3 28 24 87 21
46 30 50 20 10z m59 8 c-4 -8 -10 -15 -15 -15 -4 0 -6 7 -3 15 4 8 10 15 15
15 4 0 6 -7 3 -15z m-7622 -122 c11 -12 17 -26 14 -32 -9 -14 15 -61 31 -61 7
0 13 5 13 11 0 5 -4 7 -10 4 -11 -7 -14 8 -3 18 3 4 12 -1 19 -11 9 -11 13
-13 14 -4 0 15 20 6 20 -9 0 -6 -4 -8 -9 -4 -21 12 -22 -18 -2 -39 16 -17 20
-30 15 -50 -4 -17 -2 -26 6 -26 7 0 10 7 6 15 -3 8 -1 15 3 15 13 0 52 -70 46
-81 -4 -5 4 -9 16 -9 18 0 19 2 9 15 -9 10 -9 15 -1 15 13 0 14 -6 13 -83 -1
-56 0 -59 45 -101 39 -37 44 -46 35 -62 -11 -18 -11 -18 -12 1 0 11 -14 34
-30 50 -33 33 -80 100 -80 115 0 5 7 7 15 4 8 -4 15 -3 15 2 0 15 -34 44 -52
44 -21 0 -23 11 -6 28 6 6 8 15 4 19 -4 4 -11 1 -15 -6 -6 -10 -17 0 -37 35
-15 27 -31 51 -34 54 -13 12 -110 182 -110 194 0 17 19 3 23 -16 4 -19 5 -18
7 7 l3 30 4 -30 c3 -16 14 -40 25 -52z m7507 -47 c0 -6 -4 -20 -8 -30 -6 -17
-9 -15 -20 12 -7 18 -10 40 -6 49 6 17 7 17 21 -1 8 -11 14 -25 13 -30z m-29
-38 c0 -4 -6 -5 -13 -2 -8 3 -13 -5 -15 -21 -1 -13 -15 -45 -30 -71 -23 -36
-31 -42 -36 -29 -3 9 1 28 9 43 8 15 14 23 15 18 0 -19 29 29 30 50 0 27 21
48 32 31 4 -6 8 -15 8 -19z m-4 -75 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2
-7 1 -15 -2 -18z m-7299 -59 c-5 -5 -47 62 -47 74 0 4 12 -11 26 -31 14 -21
24 -41 21 -43z m7269 21 c-9 -13 -16 -31 -16 -39 0 -9 -10 -21 -22 -27 -14 -7
-18 -14 -11 -16 8 -3 5 -12 -10 -26 -12 -12 -30 -38 -41 -57 -10 -19 -24 -39
-30 -45 -7 -5 -24 -29 -37 -53 -25 -41 -26 -41 -37 -20 -21 43 -25 71 -11 66
8 -3 13 -15 12 -26 -2 -15 2 -20 12 -16 18 7 20 30 3 37 -7 3 -4 6 7 7 24 1
46 17 63 48 15 26 15 35 2 27 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 11 10
25 0 14 7 28 15 31 8 4 15 13 15 22 0 12 -4 12 -22 -4 -14 -12 -18 -14 -13 -4
20 37 27 41 44 23 17 -19 47 -30 37 -14 -7 11 23 71 36 71 6 0 3 -11 -6 -25z
m-7289 -91 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m68 -4 c3
-5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m7098 -10
c-3 -11 -10 -20 -15 -20 -5 0 -6 -4 -3 -10 9 -15 -22 -67 -45 -73 -25 -7 -26
9 -1 23 11 5 23 20 26 32 7 24 33 68 39 68 2 0 2 -9 -1 -20z m-7073 -45 c0
-18 7 -26 25 -31 26 -7 25 -24 -2 -24 -36 0 -70 60 -47 83 12 11 24 -3 24 -28z
m55 -65 c-3 -5 -1 -10 5 -10 7 0 9 -7 6 -16 -7 -17 9 -44 26 -44 6 0 5 7 -2
15 -7 9 -11 18 -8 21 3 3 18 -17 34 -43 27 -47 36 -59 62 -88 11 -12 11 -15 0
-15 -7 0 -22 13 -32 29 -11 16 -26 27 -33 24 -19 -7 -16 -23 5 -23 13 0 14 -2
4 -9 -11 -7 -11 -11 2 -24 9 -8 16 -19 16 -24 0 -4 -11 4 -25 19 -29 31 -44
78 -24 78 10 0 10 3 1 12 -8 8 -15 8 -25 -1 -12 -9 -15 -4 -15 36 -1 26 -7 50
-13 52 -8 2 -7 6 2 12 18 11 22 11 14 -1z m6900 -56 c-16 -25 -16 -26 4 -19
17 5 21 2 21 -14 0 -12 -9 -30 -20 -41 -12 -12 -18 -25 -15 -29 2 -5 -2 -14
-10 -21 -12 -10 -18 -10 -30 0 -8 6 -19 9 -25 5 -18 -11 -11 21 10 45 11 13
20 27 20 32 0 5 7 2 16 -6 8 -9 20 -14 25 -11 14 9 11 25 -6 25 -20 0 -19 19
2 42 23 26 28 22 8 -8z m-109 -159 c-3 -8 -9 -22 -12 -30 -7 -17 16 -17 28 0
4 5 10 7 13 3 9 -9 -32 -48 -41 -40 -4 4 -4 -3 -1 -15 4 -19 2 -21 -10 -14 -9
4 -24 6 -34 4 -18 -5 -34 -47 -24 -64 9 -14 -23 -42 -41 -35 -8 3 -24 1 -35
-4 -15 -8 -19 -7 -19 5 0 8 7 15 16 15 10 0 14 6 11 14 -3 8 1 17 9 20 8 3 14
12 14 20 0 7 6 17 13 20 47 24 72 45 71 64 0 14 4 18 14 14 10 -3 13 -1 8 6
-8 14 1 32 16 32 5 0 7 -7 4 -15z m-6646 -60 c0 -8 -2 -15 -4 -15 -2 0 -6 7
-10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m31 -18 c-1 -12 -15 -9 -19 4 -3 6
1 10 8 8 6 -3 11 -8 11 -12z m6559 -62 c-7 -9 -15 -13 -19 -10 -3 3 1 10 9 15
21 14 24 12 10 -5z m-74 -82 c-3 -4 -15 -7 -26 -8 -10 -1 -29 -10 -41 -21 -21
-19 -21 -19 -18 6 3 19 11 26 34 28 17 2 34 8 40 13 6 6 11 6 13 -1 2 -6 1
-14 -2 -17z m-6316 -37 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15
10 8 0 15 -2 15 -4z m72 -45 c-11 -6 -6 -14 18 -31 17 -12 29 -25 26 -28 -3
-2 -12 1 -21 8 -27 22 -26 5 1 -24 14 -15 23 -30 20 -34 -4 -3 -33 23 -66 58
-33 35 -58 66 -54 69 3 3 18 -9 33 -26 15 -18 31 -33 36 -33 4 0 0 9 -11 21
-14 16 -15 22 -5 28 9 6 10 11 2 15 -6 4 -11 2 -11 -5 0 -7 -2 -10 -5 -7 -3 3
-2 13 1 22 6 16 9 16 28 -3 17 -17 18 -23 8 -30z m6122 34 c-7 -16 -24 -21
-24 -6 0 8 7 13 27 20 1 1 0 -6 -3 -14z m86 5 c0 -5 -7 -10 -15 -10 -8 0 -15
-6 -15 -13 0 -18 -38 -52 -48 -43 -4 4 10 23 30 42 40 36 48 40 48 24z m-6162
-60 c7 -11 9 -20 4 -20 -10 0 -32 22 -32 32 0 15 15 8 28 -12z m71 -80 c19
-10 21 -14 10 -22 -10 -7 -8 -7 9 -3 17 5 22 2 22 -13 0 -16 -5 -19 -21 -15
-18 5 -20 3 -14 -17 7 -21 3 -20 -33 11 -23 19 -36 36 -31 38 6 1 19 -5 30
-15 19 -17 20 -17 13 1 -4 10 -16 27 -26 37 -10 10 -18 26 -18 36 0 15 3 14
18 -4 9 -12 28 -27 41 -34z m99 -81 c10 -16 10 -19 1 -13 -9 5 -10 2 -6 -10 4
-11 3 -15 -4 -11 -6 4 -8 13 -5 21 3 8 -1 14 -9 14 -8 0 -15 5 -15 10 0 18 25
11 38 -11z m202 -174 c7 -8 19 -15 26 -15 8 0 14 -5 14 -10 0 -17 -30 -24 -43
-10 -9 10 -9 11 1 6 21 -12 13 12 -10 28 -12 9 -17 16 -11 16 6 0 16 -7 23
-15z m-56 -30 c4 -13 2 -14 -14 -5 -23 13 -26 20 -6 20 8 0 16 -7 20 -15z m30
-30 c-4 -8 -10 -12 -15 -9 -11 6 -2 24 11 24 5 0 7 -7 4 -15z m79 8 c-25 -10
-13 -25 15 -19 l27 7 -28 -26 c-25 -24 -29 -25 -49 -10 l-22 15 22 0 c13 0 20
4 17 10 -8 13 3 30 18 29 9 0 9 -2 0 -6z m5167 -2 c0 -5 -9 -13 -20 -16 -11
-4 -18 -11 -15 -16 4 -5 -1 -9 -9 -9 -9 0 -16 -5 -16 -11 0 -7 11 -9 30 -5 17
3 30 1 30 -4 0 -6 -7 -10 -16 -10 -9 0 -18 -9 -21 -21 -4 -12 -17 -22 -34 -26
-16 -3 -29 -9 -29 -13 0 -10 -50 -31 -60 -25 -5 3 -12 0 -15 -5 -4 -6 -19 -11
-33 -10 -41 3 -45 1 -28 -15 8 -9 22 -12 33 -9 13 5 10 -1 -9 -20 -15 -14 -35
-25 -45 -25 -17 1 -17 2 0 6 9 2 15 9 11 14 -3 5 -25 -3 -49 -17 -25 -14 -50
-24 -57 -22 -6 2 6 14 27 26 20 13 35 25 33 28 -7 7 -38 -7 -38 -17 0 -5 -6
-9 -12 -9 -7 1 -3 9 10 20 16 14 32 17 60 14 33 -5 35 -4 19 9 -21 17 -11 28
47 52 20 9 34 20 31 25 -7 11 -3 12 19 4 8 -3 12 -12 9 -20 -7 -18 4 -18 23 0
12 13 11 15 -6 21 -11 3 -20 10 -20 15 0 10 31 22 38 15 3 -3 0 -5 -6 -5 -7 0
-12 -4 -12 -10 0 -16 19 -12 41 9 11 10 19 26 17 35 -2 9 10 24 27 35 34 22
45 25 45 12z m-4906 -197 c28 7 66 -8 66 -26 0 -6 -8 -4 -18 5 -23 21 -38 21
-46 1 -4 -12 -1 -14 13 -9 11 3 22 1 26 -5 3 -5 1 -10 -4 -10 -6 0 -11 -4 -11
-10 0 -5 7 -10 15 -10 8 0 15 3 15 8 0 17 62 6 84 -15 13 -13 26 -23 29 -23 3
0 34 -14 69 -31 49 -24 56 -29 30 -25 -19 3 -31 2 -27 -3 3 -5 -3 -11 -12 -14
-14 -3 -12 -6 11 -16 16 -6 26 -14 24 -17 -3 -3 -22 3 -41 12 -35 17 -36 18
-17 34 26 23 25 28 -5 28 -14 0 -34 7 -44 16 -23 21 -55 22 -47 1 5 -13 2 -14
-16 -4 -16 9 -18 13 -7 20 11 7 9 9 -6 9 -17 0 -17 2 -5 10 17 11 1 13 -25 4
-13 -5 -14 -8 -3 -15 8 -6 9 -9 2 -9 -7 0 -22 11 -35 25 -13 13 -32 25 -41 25
-12 0 -15 5 -11 17 4 10 2 14 -4 10 -5 -4 -19 0 -29 9 -18 14 -18 15 4 9 15
-4 22 -2 22 7 0 10 2 10 9 -1 6 -9 18 -11 35 -7z m4522 -57 c-18 -13 -18 -15
3 -34 l22 -21 -28 -6 c-20 -6 -31 -3 -39 9 -11 14 -8 21 14 41 15 13 31 24 36
24 6 0 2 -6 -8 -13z m102 -9 c-7 -20 -57 -32 -85 -19 -14 7 -23 14 -20 17 3 3
15 0 27 -6 18 -9 24 -9 30 0 4 7 13 9 19 6 6 -4 11 -2 11 3 0 6 5 11 11 11 6
0 9 -5 7 -12z m-200 -55 c-10 -2 -18 -9 -18 -14 0 -5 -7 -9 -15 -9 -8 0 -15
-4 -15 -10 0 -5 4 -10 9 -10 6 0 13 5 16 10 8 13 65 13 65 1 0 -5 -25 -23 -55
-39 -30 -17 -55 -27 -55 -23 0 4 21 17 46 29 26 13 44 25 41 28 -3 3 -20 -2
-38 -13 -24 -13 -33 -15 -36 -5 -3 6 -11 12 -19 12 -8 0 -14 3 -14 8 0 9 70
41 90 40 10 0 10 -2 -2 -5z m-4271 -19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m123 -34 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4
10 6 0 11 -4 11 -10z m117 -30 l26 -20 -30 0 c-22 0 -29 5 -30 20 -1 11 1 20
4 20 2 0 16 -9 30 -20z m-87 -14 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z m135 -16 c-3 -5 1 -10 9 -10 9 0 16 -4 16 -8 0
-4 10 -9 22 -9 13 -1 24 -7 26 -12 2 -5 9 -8 17 -5 7 3 37 -7 66 -22 45 -23
50 -29 32 -32 -45 -7 -16 -42 34 -42 18 0 33 -5 33 -10 0 -15 -76 -1 -130 23
-25 11 -40 22 -33 24 7 2 21 0 32 -6 25 -14 41 -14 21 0 -11 8 -12 12 -2 16 6
2 12 9 12 14 0 13 -28 11 -32 -2 -2 -6 -13 -9 -23 -7 -18 5 -18 6 4 15 17 7
21 12 12 17 -7 5 -18 3 -23 -2 -8 -8 -24 -4 -55 14 -23 14 -43 21 -43 15 0 -8
-10 -6 -30 4 -34 18 -54 20 -44 4 4 -8 1 -8 -12 -1 -11 5 -26 12 -34 15 -8 2
8 4 35 2 28 -1 58 -5 68 -10 13 -5 16 -4 11 9 -3 9 -1 16 6 16 6 0 8 -4 5 -10z
m-24 -50 c31 -26 15 -26 -18 0 -14 11 -21 20 -15 20 5 0 20 -9 33 -20z m3723
-33 c25 2 27 0 11 -6 -11 -5 -35 -6 -54 -3 -22 3 -36 0 -41 -9 -5 -7 -12 -10
-16 -6 -4 4 1 14 12 22 12 9 14 14 6 15 -7 0 -10 4 -7 10 4 6 16 3 32 -7 14
-9 40 -16 57 -16z m-3404 -49 c0 -13 -31 -9 -46 6 -21 22 -17 25 16 13 17 -6
30 -15 30 -19z m3366 24 c-3 -5 -30 -17 -60 -27 -30 -10 -53 -22 -50 -27 7
-11 -3 -10 -26 2 -11 6 -17 17 -14 25 9 23 -81 -8 -126 -43 -27 -22 -43 -29
-63 -25 -15 3 -34 0 -42 -7 -11 -9 -19 -10 -26 -3 -8 8 -1 13 23 18 18 4 38
11 44 17 7 5 20 10 30 13 11 2 48 14 84 27 44 16 83 23 120 22 30 -1 62 2 70
7 19 11 43 12 36 1z m-3288 -54 c2 -4 12 -8 23 -8 11 0 27 -4 37 -9 38 -19 78
-35 97 -37 11 -2 31 -7 45 -13 21 -9 19 -10 -15 -5 -22 3 -48 8 -57 11 -10 2
-18 0 -18 -6 0 -6 8 -11 18 -12 12 -1 9 -4 -8 -10 -19 -7 -16 -8 17 -4 31 4
46 1 59 -12 17 -17 17 -18 -2 -21 -26 -4 -147 41 -152 56 -2 7 6 12 17 12 12
0 21 5 21 11 0 6 -9 9 -20 6 -11 -3 -20 -1 -20 5 0 5 -8 7 -19 3 -12 -3 -21 0
-25 10 -3 8 -18 15 -33 16 -15 0 -22 3 -14 6 11 5 11 7 0 15 -9 5 -3 6 16 2
17 -4 32 -11 33 -16z m-73 2 c8 -13 -5 -13 -25 0 -13 8 -13 10 2 10 9 0 20 -4
23 -10z m3180 0 c-3 -6 4 -10 16 -10 12 0 20 -3 16 -6 -3 -3 -25 -3 -49 1 -35
6 -39 9 -23 16 29 11 47 11 40 -1z m-3218 -16 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m106 -26 c18 -9 22 -24 9 -32 -5 -3 -16 4 -25 15 -9 10
-28 19 -42 19 -14 0 -25 5 -25 11 0 11 51 4 83 -13z m3097 13 c0 -5 -16 -12
-35 -15 -19 -4 -38 -14 -43 -23 -7 -11 -11 -12 -16 -4 -11 17 34 50 67 51 15
0 27 -4 27 -9z m-236 -43 c-5 -8 0 -9 17 -4 13 4 35 9 49 11 19 2 19 1 3 -2
-24 -5 -32 -23 -11 -23 28 0 -31 -28 -82 -39 -30 -7 -64 -15 -75 -18 -13 -3
-8 3 12 17 18 12 53 23 79 26 52 5 73 14 33 14 -14 0 -30 5 -36 11 -8 8 -17 8
-31 0 -10 -6 -22 -7 -26 -4 -3 3 -6 1 -6 -5 0 -7 -8 -12 -17 -12 -35 0 -48
-16 -36 -43 11 -23 9 -25 -20 -30 -18 -3 -40 -8 -49 -12 -13 -5 -17 -2 -15 17
0 12 6 27 12 31 6 5 2 7 -10 5 -12 -2 -19 -11 -19 -21 1 -10 -5 -17 -17 -17
-24 0 -34 17 -12 23 16 4 16 5 1 6 -9 0 -24 -6 -33 -15 -10 -10 -24 -14 -40
-10 -15 4 -25 2 -25 -5 0 -6 15 -10 38 -8 61 3 97 0 87 -9 -13 -11 -87 -32
-111 -32 -32 0 6 19 41 21 34 1 22 5 -48 14 -43 6 -46 8 -32 22 9 8 24 13 35
10 10 -3 32 4 49 14 17 10 31 17 31 14 0 -2 12 0 27 6 20 7 34 7 51 -3 22 -11
26 -10 40 10 8 12 22 22 31 22 9 1 27 7 41 15 31 18 84 20 74 3z m121 -8 c-3
-5 -14 -10 -23 -9 -14 0 -13 2 3 9 27 11 27 11 20 0z m-140 -10 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-2561 -25 c3 -8 1
-15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m126 -8 c14 0
20 -1 14 -4 -7 -2 -10 -13 -7 -23 4 -14 0 -20 -11 -20 -9 0 -16 7 -16 15 0 8
-9 15 -20 15 -12 0 -20 7 -20 16 0 12 5 14 18 8 9 -4 28 -7 42 -7z m-50 -27
c12 -8 10 -10 -10 -11 -17 -1 -19 -3 -7 -6 9 -2 17 -9 17 -14 0 -5 -15 -3 -32
4 -18 8 -39 12 -47 9 -12 -4 -12 -3 0 11 16 18 56 22 79 7z m145 -1 l30 -1
-35 -8 c-25 -6 -39 -4 -55 7 -20 15 -19 15 5 9 14 -3 39 -6 55 -7z m-51 -28
c8 -12 -41 -24 -57 -14 -8 5 -3 10 13 14 36 10 38 10 44 0z m157 -1 c10 -6 19
-18 19 -27 0 -13 -2 -14 -17 -2 -9 8 -24 13 -33 11 -9 -1 -23 4 -30 13 -11 13
-9 15 14 15 15 0 36 -5 47 -10z m-108 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m1917 -13 c-20 -13 -57 -13 -44 -1 10 10 46 19 54 14 2 -2
-2 -8 -10 -13z m-1700 0 c0 -13 -11 -13 -30 0 -12 8 -11 10 8 10 12 0 22 -4
22 -10z m1610 0 c-13 -8 -12 -10 3 -10 9 0 17 -5 17 -11 0 -7 -7 -8 -17 -4
-10 4 -38 8 -63 8 -42 0 -42 0 -7 4 20 2 37 8 37 14 0 5 10 9 23 9 18 0 19 -2
7 -10z m-1840 -10 c11 -8 7 -9 -15 -4 -37 8 -45 14 -19 14 10 0 26 -5 34 -10z
m205 -10 c-4 -6 8 -10 30 -10 23 0 34 -4 29 -11 -3 -6 -17 -8 -33 -3 -14 3
-35 8 -45 9 -31 3 -32 25 -1 25 15 0 24 -4 20 -10z m83 3 c-10 -2 -26 -2 -35
0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m126 -8 c68 -8 59 -24 -14 -26 l-45 -2
35 -8 c24 -5 7 -6 -50 -4 -60 3 -76 1 -55 -5 44 -13 -68 -2 -123 12 -66 17
-59 27 8 12 58 -14 182 -6 199 12 3 4 -4 5 -16 2 -14 -4 -24 0 -27 9 -4 10 -1
13 12 9 9 -2 44 -7 76 -11z m1310 1 c-8 -20 62 -39 102 -27 16 4 37 5 48 1 11
-4 -19 -13 -80 -23 -99 -17 -146 -23 -284 -33 -41 -2 -85 -10 -97 -16 -26 -13
-38 -6 -30 15 4 11 38 16 139 22 84 5 126 10 113 16 -16 6 -11 8 20 9 35 2 37
3 15 10 l-25 7 25 5 c14 3 -2 4 -34 2 -74 -5 -68 4 10 15 85 12 84 12 78 -3z
m279 -3 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-191 -14 c-15
-6 -30 -5 -40 1 -13 7 -8 9 23 9 35 0 37 -1 17 -10z m-959 -25 c7 -7 314 -5
345 3 13 3 22 1 22 -6 0 -6 -10 -11 -22 -12 -22 -1 -22 -1 2 -9 19 -6 9 -8
-37 -9 -35 -1 -63 -5 -63 -10 0 -5 -10 -12 -22 -14 -13 -2 -1 -5 25 -6 30 0
46 3 42 9 -4 6 10 10 34 10 44 0 55 -16 18 -25 -13 -4 -102 -4 -198 -1 -96 3
-210 6 -254 7 l-80 1 70 8 c39 4 93 8 120 8 42 0 45 -2 20 -8 -22 -6 -12 -8
40 -8 52 0 62 2 40 8 -16 4 -25 9 -20 11 26 7 -126 13 -170 6 -95 -14 -231
-11 -255 6 -20 14 -7 15 159 14 163 -2 213 3 164 16 -15 4 -15 5 -2 6 9 0 18
-2 22 -5z m447 -19 c0 -16 -9 -19 -35 -9 -13 5 -14 8 -3 15 20 13 38 11 38 -6z
m-885 -5 c4 -7 -8 -8 -37 -4 -57 9 -61 14 -10 14 22 0 44 -5 47 -10z m242 -36
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m681 -1 c-15 -2 -42 -2
-60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z"/>
<path d="M1867 7514 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M9350 6735 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M9341 6684 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1446 6675 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M1421 6654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9643 5395 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M9642 5300 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1120 5159 c0 -8 -5 -43 -10 -79 -11 -75 -2 -231 12 -206 5 9 8 40 8
68 -1 29 -1 91 -1 137 0 47 -1 87 -4 89 -3 3 -5 -1 -5 -9z"/>
<path d="M1143 4925 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M1113 4755 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M9612 4300 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1286 3924 c-4 -14 -5 -28 -3 -31 3 -2 8 8 11 23 4 14 5 28 3 31 -3
2 -8 -8 -11 -23z"/>
<path d="M9430 3729 c0 -8 7 -24 16 -35 14 -18 15 -18 8 6 -8 31 -24 50 -24
29z"/>
<path d="M1400 3510 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M9255 3290 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1800 2726 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M9111 3024 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9031 2874 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8986 2761 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M8780 2501 c-7 -13 -7 -22 0 -26 5 -3 10 1 10 10 0 9 5 13 10 10 6
-3 10 -2 10 4 0 20 -19 21 -30 2z"/>
<path d="M8631 2314 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8601 2269 c-11 -19 -11 -21 4 -15 9 3 19 6 21 6 2 0 4 7 4 15 0 21
-13 19 -29 -6z"/>
<path d="M7910 1610 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3975 1040 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M4055 1010 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M6566 961 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M4778 883 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6098 853 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5355 830 c66 -5 73 -7 40 -11 l-40 -6 43 -1 c23 -1 42 2 42 7 0 6
19 12 43 14 23 2 -14 3 -83 3 -110 0 -115 0 -45 -6z"/>
<path d="M2047 7818 c-31 -38 -32 -44 -11 -52 8 -3 13 -2 9 3 -3 5 4 26 16 45
26 45 21 46 -14 4z"/>
<path d="M1235 7630 c-14 -22 -25 -42 -25 -46 0 -3 53 -63 118 -132 64 -69
129 -139 144 -155 l27 -29 20 33 c20 32 20 33 0 60 -11 16 -24 31 -30 34 -5 4
4 32 23 66 33 64 32 63 99 43 12 -3 24 9 42 41 14 25 24 45 23 45 -2 0 -89 18
-195 40 -105 22 -198 40 -206 40 -8 0 -26 -18 -40 -40z m175 -81 c27 -6 50
-15 50 -19 0 -19 -24 -60 -35 -60 -6 0 -31 20 -55 45 -48 49 -45 52 40 34z"/>
<path d="M9378 7548 c-93 -51 -168 -97 -168 -101 0 -11 130 -247 136 -247 8 0
64 30 64 34 0 2 -20 39 -44 82 -24 44 -41 84 -39 90 5 13 47 34 67 34 7 0 21
-17 30 -37 9 -21 25 -49 35 -62 18 -24 20 -24 45 -8 14 10 26 22 26 27 0 5
-13 32 -30 60 -16 28 -30 55 -30 61 0 10 67 46 73 38 2 -2 23 -39 46 -81 23
-43 44 -78 47 -78 11 0 54 33 54 41 0 18 -125 238 -135 239 -5 0 -85 -42 -177
-92z"/>
<path d="M6655 7543 c-87 -12 -266 -83 -303 -120 -7 -7 -16 -13 -20 -13 -22
-1 -202 -199 -202 -223 0 -4 -13 -35 -30 -68 -16 -33 -32 -81 -36 -108 l-6
-48 88 -31 c141 -50 175 -56 298 -49 127 7 270 46 320 89 6 5 33 23 60 40 92
59 206 198 192 233 -3 10 0 12 11 8 13 -5 15 -2 9 12 -4 11 -4 16 0 12 4 -3
22 30 41 76 19 45 37 88 40 96 5 11 2 13 -11 8 -10 -4 -24 -7 -33 -7 -12 0
-14 -9 -9 -42 l6 -43 -13 40 c-8 24 -9 43 -4 46 5 4 5 9 -1 13 -6 3 -17 -1
-25 -9 -9 -8 -24 -15 -34 -15 -14 0 -15 2 -4 9 10 6 -5 11 -45 15 -128 12
-169 18 -177 26 -14 12 125 23 154 11 15 -5 35 -8 45 -5 11 2 33 0 49 -6 28
-9 27 -10 -12 -6 -24 2 -43 0 -43 -6 0 -5 24 -8 53 -7 28 1 61 -1 72 -5 19 -7
19 -7 2 7 -24 20 -137 54 -217 67 -64 10 -156 11 -215 3z m80 -21 c-3 -3 -11
0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m-67 1 c-10 -2 -26 -2 -35 0 -10
3 -2 5 17 5 19 0 27 -2 18 -5z m-118 -33 c-20 -13 -43 -13 -35 0 3 6 16 10 28
10 18 0 19 -2 7 -10z m9 -26 c2 -2 -19 -4 -48 -4 -29 0 -50 4 -47 9 5 8 85 4
95 -5z m161 7 c0 -5 15 -12 33 -16 l32 -8 -30 -5 c-16 -2 -31 1 -33 7 -2 6
-19 11 -38 11 -21 0 -33 4 -29 10 8 12 65 13 65 1z m-243 -27 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m71 -1 c-10 -2 -26 -2 -35 0 -10 3 -2
5 17 5 19 0 27 -2 18 -5z m-202 -62 c-17 -19 -17 -19 18 -17 32 3 36 1 36 -20
0 -27 -12 -31 -30 -9 -18 22 -39 18 -51 -7 -9 -21 -9 -21 -4 5 6 28 -11 38
-21 12 -4 -8 -11 -15 -18 -15 -6 1 7 16 29 35 43 37 69 47 41 16z m44 5 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m661 -54
c-7 -12 -9 -5 -9 24 0 33 2 36 9 16 5 -14 5 -30 0 -40z m-806 -32 c-11 -12
-20 -27 -21 -35 -3 -18 -44 -77 -60 -88 -8 -5 -11 -29 -9 -74 3 -60 2 -65 -10
-43 -9 16 -10 32 -4 48 12 30 11 41 -1 22 -5 -8 -12 -12 -15 -8 -9 9 27 78 42
78 7 0 10 6 7 15 -4 8 -1 15 5 15 7 0 15 8 18 18 10 26 46 72 56 72 6 0 2 -9
-8 -20z m65 -5 c14 -16 10 -18 -14 -9 -9 3 -16 10 -16 15 0 14 16 11 30 -6z
m693 -28 c-4 -7 -6 -22 -4 -34 3 -31 -15 -29 -23 2 -4 14 -13 25 -21 25 -8 0
-15 4 -15 9 0 5 16 9 35 9 24 0 33 -4 28 -11z m-82 -38 c13 -25 0 -43 -24 -33
-25 9 -21 54 4 54 5 0 13 -9 20 -21z m50 -51 c-6 -13 -10 -16 -11 -8 0 13 -1
13 -10 0 -7 -10 -10 -11 -10 -2 0 7 6 15 14 18 8 3 12 14 9 27 -4 19 -3 20 6
5 7 -10 8 -24 2 -40z m-748 -73 c2 -22 2 -30 0 -17 -3 14 -12 22 -24 22 -21 0
-27 35 -6 43 19 7 26 -4 30 -48z m677 32 c0 -7 -6 -19 -12 -26 -11 -11 -9 -11
12 -1 l25 13 -19 -22 c-24 -26 -40 -14 -30 23 6 27 24 36 24 13z m-100 -27 c0
-5 -9 -10 -21 -10 -11 0 -17 5 -14 10 3 6 13 10 21 10 8 0 14 -4 14 -10z
m-685 -30 c-5 -21 -3 -30 6 -30 7 0 10 -2 7 -5 -3 -3 -15 -2 -27 3 -20 7 -21
9 -7 21 9 8 16 20 16 27 0 8 3 14 6 14 3 0 3 -13 -1 -30z m635 -10 c0 -5 -7
-10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m84 -25 c-4
-9 -13 -14 -22 -13 -8 2 -17 -4 -19 -12 -4 -13 -3 -13 6 0 8 11 11 11 11 2 0
-13 -6 -16 -51 -26 -19 -4 -29 -1 -33 9 -3 10 0 14 12 12 9 -1 17 1 17 5 0 13
40 37 62 38 16 0 21 -4 17 -15z m-594 -82 c81 -7 90 -13 21 -13 -35 0 -50 -4
-45 -11 3 -6 15 -9 25 -6 11 3 17 1 13 -4 -8 -13 10 -11 24 3 9 9 20 9 46 0
39 -14 188 -1 264 24 51 17 55 17 47 5 -3 -6 10 -8 32 -4 l38 5 -38 -20 c-32
-17 -50 -19 -113 -15 -49 4 -74 2 -74 -5 0 -6 10 -12 23 -14 12 -2 -36 -3
-108 -2 -129 1 -212 16 -227 40 -5 7 -18 10 -30 7 -18 -4 -20 -2 -9 5 12 8 10
11 -10 15 -13 2 -6 2 16 0 22 -2 69 -7 105 -10z m495 7 c3 -5 1 -10 -4 -10 -6
0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-335 -14 c0 -11 -19 -15 -25
-6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m107 -2 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m66 -41 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2
18 -5z"/>
<path d="M6588 6943 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6170 7500 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1066 7325 l-28 -64 33 -28 c62 -51 144 -127 147 -135 2 -4 -9 -6
-25 -3 -15 3 -55 9 -87 15 -33 5 -76 10 -96 10 -35 0 -37 -3 -61 -57 -13 -32
-24 -62 -24 -66 0 -4 41 -26 90 -48 50 -22 113 -51 140 -64 56 -25 126 -55
131 -55 10 0 35 63 27 70 -4 3 -68 33 -141 65 -73 32 -131 60 -128 62 3 4 205
-19 283 -32 28 -5 34 -2 48 24 l15 30 -78 68 c-42 37 -97 85 -121 105 -25 21
-42 41 -38 44 4 4 12 2 19 -3 14 -11 245 -113 256 -113 4 0 15 18 24 41 l17
40 -62 28 c-225 102 -266 120 -288 125 -22 6 -27 0 -53 -59z"/>
<path d="M9548 7222 c-93 -42 -168 -81 -168 -88 0 -27 69 -160 96 -186 57 -52
136 -63 214 -27 88 40 136 110 128 188 -7 60 -67 191 -88 190 -8 -1 -90 -35
-182 -77z m181 -63 c19 -54 13 -79 -23 -114 -67 -63 -163 -65 -204 -5 -12 18
-21 38 -20 42 4 10 205 106 225 107 6 1 16 -13 22 -30z"/>
<path d="M8152 7258 c5 -15 28 -23 28 -10 0 5 -7 13 -16 16 -10 4 -14 1 -12
-6z"/>
<path d="M1656 7204 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M8321 7164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7175 6994 c-11 -3 -47 -10 -80 -16 -56 -11 -84 -21 -192 -69 -39
-18 -42 -21 -33 -45 17 -43 12 -64 -12 -64 -21 0 -21 0 -2 16 18 15 18 15 -9
9 -36 -7 -62 7 -27 15 14 3 26 14 28 24 3 16 -2 14 -35 -10 -58 -43 -111 -95
-135 -134 -12 -19 -25 -37 -28 -40 -16 -13 -60 -86 -60 -101 0 -14 78 -79 96
-79 4 0 15 -6 23 -13 9 -8 48 -27 86 -42 39 -16 75 -32 80 -36 16 -11 148 -29
213 -29 60 0 161 15 197 30 11 4 52 20 90 35 39 16 75 33 80 39 6 6 39 34 75
61 36 27 73 59 83 72 10 13 25 30 33 38 9 9 13 23 9 35 -3 11 -2 20 3 20 6 0
12 -5 14 -12 2 -6 16 14 31 45 15 31 24 62 21 69 -6 16 -84 72 -84 61 0 -5 5
-14 12 -21 9 -9 8 -12 -4 -12 -17 0 -32 26 -23 40 11 17 -135 69 -255 90 -25
5 -65 13 -90 19 -46 11 -70 12 -105 5z m120 -25 c13 -6 -2 -7 -47 -3 -38 3
-68 2 -68 -3 0 -5 -19 -7 -42 -4 -38 4 -40 5 -18 12 36 10 151 9 175 -2z
m-255 -24 c0 -8 -7 -15 -15 -15 -21 0 -19 -20 3 -22 9 0 -5 -4 -33 -8 l-50 -7
48 -1 c46 -2 57 -13 39 -37 -14 -20 -34 -16 -27 5 6 18 2 20 -34 20 -46 0 -63
16 -26 26 14 3 25 10 25 15 0 5 9 9 20 9 10 0 25 7 32 15 16 18 18 18 18 0z
m413 -12 c-23 -9 -13 -22 20 -25 l32 -3 -39 -6 c-27 -4 -43 -1 -54 10 -14 14
-13 16 9 22 28 8 49 10 32 2z m86 -19 c18 -9 30 -18 28 -21 -8 -7 -57 9 -52
17 3 5 -3 11 -12 13 -10 3 -13 5 -7 6 6 0 25 -6 43 -15z m71 -54 l0 -30 -25
22 c-19 16 -22 24 -13 30 25 15 38 8 38 -22z m-690 11 c0 -11 -26 -22 -34 -14
-12 12 -5 23 14 23 11 0 20 -4 20 -9z m573 2 c-7 -2 -21 -2 -30 0 -10 3 -4 5
12 5 17 0 24 -2 18 -5z m-500 -37 c4 -9 0 -13 -11 -11 -9 2 -21 4 -27 5 -5 1
-1 5 10 10 11 5 21 9 21 9 1 1 4 -5 7 -13z m417 0 c-37 -13 -54 -11 -45 5 4 5
23 9 43 8 l37 0 -35 -13z m134 -4 c-1 -7 -2 -21 -2 -32 -1 -11 -6 -20 -11 -20
-10 0 -22 51 -14 63 7 11 28 3 27 -11z m160 -24 c6 -10 4 -23 -8 -39 l-18 -24
5 30 c2 17 2 24 0 18 -6 -18 -22 -16 -30 3 -4 11 -13 14 -30 9 -21 -5 -23 -4
-11 9 16 18 79 14 92 -6z m-941 -28 c-3 -10 -12 -25 -21 -32 -13 -11 -14 -11
-9 2 4 8 10 23 13 33 9 24 23 22 17 -3z m37 0 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m791 -56 c-22 -28 -29 -74 -11 -74
6 0 10 -9 10 -20 0 -11 -4 -20 -9 -20 -5 0 -16 -6 -23 -12 -26 -24 -57 -38
-63 -28 -7 12 22 70 35 70 6 0 10 13 10 29 0 42 19 89 38 94 29 6 35 -11 13
-39z m-792 2 c0 -6 -3 -6 -6 2 -4 10 -8 10 -19 1 -11 -9 -14 -8 -14 3 0 8 3
18 7 21 8 9 33 -12 32 -27z m-64 1 c6 -5 3 -7 -7 -3 -10 3 -20 1 -23 -4 -4 -6
-11 -10 -17 -10 -6 0 -3 8 8 19 10 10 21 16 24 12 3 -3 10 -10 15 -14z m941
-9 c4 -7 3 -8 -5 -4 -6 4 -23 0 -37 -9 -30 -20 -46 -11 -18 10 23 17 50 19 60
3z m-31 -38 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
9 -10z m-950 -10 c3 -5 2 -10 -4 -10 -5 0 -18 -3 -28 -7 -16 -6 -16 -5 -4 10
15 19 27 22 36 7z m919 -30 c-6 -6 -10 -2 -11 12 -2 16 1 18 10 9 7 -7 8 -14
1 -21z m-218 -45 c-19 -29 -21 -52 -3 -58 9 -3 9 -7 -2 -20 -20 -25 -31 -11
-31 43 0 42 3 49 23 53 12 2 24 5 26 6 2 0 -4 -10 -13 -24z m-746 0 c0 -9 16
-23 35 -32 19 -9 35 -21 35 -27 0 -6 -5 -5 -12 2 -14 14 -28 16 -28 3 0 -5 9
-13 20 -16 11 -3 20 -11 20 -17 0 -6 -11 -3 -25 6 -13 9 -31 16 -40 16 -18 0
-20 16 -2 24 8 4 7 5 -3 5 -32 -2 -40 2 -40 19 0 10 3 22 7 25 12 13 33 7 33
-8z m70 -5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m753 -44 c32 12 17 -15 -17 -31 -46 -22 -66 -10 -35 20 13 12 27 18
30 13 3 -5 13 -6 22 -2z m-623 -71 c19 -7 31 -14 26 -14 -5 -1 -3 -8 4 -16 8
-10 9 -15 2 -15 -19 0 -62 23 -62 33 0 5 -7 6 -16 3 -22 -8 -40 4 -23 15 18
11 26 10 69 -6z m120 -5 c0 -5 -9 -10 -19 -10 -11 0 -23 5 -26 10 -4 6 5 10
19 10 14 0 26 -4 26 -10z m358 2 c-16 -2 -28 -8 -28 -13 0 -13 15 -11 57 6 54
23 64 17 13 -7 -25 -11 -64 -23 -88 -26 -36 -4 -41 -2 -36 12 5 12 0 16 -20
16 -14 0 -26 4 -26 8 0 5 35 8 78 8 42 0 65 -2 50 -4z m-298 -51 c14 -5 21
-11 15 -12 -5 -1 13 -5 40 -9 46 -7 45 -7 -15 -4 -68 3 -130 20 -130 35 0 10
54 5 90 -10z"/>
<path d="M7533 6633 c-7 -2 -13 -11 -13 -19 0 -12 4 -12 20 -4 32 17 26 37 -7
23z"/>
<path d="M1082 6717 c-20 -23 -109 -302 -156 -487 -96 -377 -137 -714 -137
-1130 -1 -347 20 -575 81 -895 106 -557 349 -1154 667 -1639 77 -116 277 -385
323 -434 35 -36 64 -41 80 -13 16 30 13 36 -43 103 -130 154 -282 373 -422
607 -65 109 -221 431 -270 556 -25 66 -51 131 -57 145 -55 129 -158 505 -198
720 -63 347 -74 469 -74 845 0 322 10 463 50 715 39 247 120 567 194 772 37
103 37 142 -1 146 -14 2 -31 -3 -37 -11z"/>
<path d="M9657 6723 c-14 -13 -6 -49 48 -215 105 -323 163 -596 207 -973 20
-174 17 -743 -6 -930 -76 -631 -256 -1193 -555 -1729 -45 -81 -81 -154 -79
-163 5 -25 35 -40 56 -27 27 17 93 131 200 344 263 526 407 1029 473 1660 16
146 15 647 0 805 -27 276 -73 543 -132 765 -42 160 -139 454 -153 462 -14 10
-50 10 -59 1z"/>
<path d="M4585 6609 c-245 -143 -424 -384 -496 -669 -21 -84 -19 -386 4 -470
79 -294 253 -521 508 -666 34 -19 63 -34 66 -34 2 0 -26 30 -63 68 -198 198
-314 455 -344 759 -12 123 -1 283 26 408 49 218 201 485 347 609 55 47 38 46
-48 -5z"/>
<path d="M6180 6604 c110 -103 236 -299 289 -449 165 -465 60 -983 -265 -1310
-41 -41 -73 -75 -71 -75 15 0 135 72 195 117 434 327 535 948 227 1408 -60 91
-219 242 -311 297 -111 66 -124 69 -64 12z"/>
<path d="M4870 6411 c-140 -84 -259 -231 -320 -393 -134 -360 19 -774 355
-959 l60 -33 -63 65 c-111 112 -190 256 -232 424 -33 128 -33 331 -1 460 41
160 122 312 225 419 30 31 51 56 48 56 -4 0 -36 -18 -72 -39z"/>
<path d="M5860 6447 c0 -2 23 -28 52 -58 182 -191 274 -485 239 -766 -25 -205
-105 -376 -245 -527 -25 -28 -44 -52 -42 -54 7 -6 87 48 159 106 110 90 205
237 253 397 29 95 27 316 -4 415 -61 192 -173 343 -332 445 -66 42 -80 50 -80
42z"/>
<path d="M5650 6224 c0 -3 23 -31 50 -61 55 -60 120 -186 141 -273 8 -30 14
-107 14 -170 0 -63 -6 -140 -14 -170 -21 -88 -86 -213 -142 -274 -28 -31 -49
-59 -47 -62 9 -9 123 82 171 137 182 207 182 531 1 737 -53 60 -174 155 -174
136z"/>
<path d="M5073 6177 c-317 -234 -307 -707 19 -928 60 -41 77 -40 33 1 -47 44
-115 155 -141 228 -86 249 -36 516 132 702 47 52 31 51 -43 -3z"/>
<path d="M5323 5852 c-129 -52 -173 -206 -93 -319 l33 -47 -32 -141 c-29 -127
-130 -629 -151 -750 -5 -27 -16 -84 -25 -125 -9 -41 -27 -131 -40 -200 -23
-121 -68 -341 -100 -485 -8 -38 -31 -144 -49 -235 -19 -91 -57 -275 -85 -410
-69 -332 -148 -726 -156 -777 -8 -48 -16 -43 112 -67 l72 -13 95 96 95 95 58
-28 c87 -42 185 -110 230 -159 40 -43 40 -43 109 -42 62 0 72 4 99 29 17 16
91 69 165 118 l134 90 86 -89 c70 -74 90 -89 106 -83 11 5 55 14 98 20 43 7
80 18 82 25 3 6 -5 51 -16 101 -12 49 -66 312 -120 584 -55 272 -113 560 -130
640 -17 80 -39 190 -50 245 -10 55 -31 156 -45 225 -14 69 -30 148 -35 175 -5
28 -32 160 -60 295 -106 520 -160 807 -160 852 0 20 5 40 10 43 25 15 50 86
50 140 0 93 -53 169 -139 200 -55 19 -95 18 -148 -3z m176 -667 c17 -88 43
-218 57 -288 15 -70 24 -130 21 -134 -3 -3 -29 14 -56 38 -59 49 -69 75 -72
199 -4 179 1 364 10 355 5 -6 23 -82 40 -170z m-150 35 c19 -167 11 -269 -25
-345 -19 -42 -94 -119 -104 -108 -4 4 3 60 17 123 56 270 74 357 80 393 3 20
9 37 13 37 4 0 12 -45 19 -100z m170 -552 c66 -62 117 -143 102 -162 -16 -19
-147 -106 -159 -106 -9 0 -12 46 -12 160 0 88 3 160 7 160 3 0 31 -24 62 -52z
m-169 -123 c0 -91 -3 -165 -6 -165 -4 0 -37 21 -75 46 -73 48 -99 77 -99 107
0 20 150 177 169 177 8 0 11 -50 11 -165z m-96 -234 c42 -29 76 -59 76 -66 0
-23 -248 -288 -261 -279 -16 9 -10 61 27 232 46 214 40 197 63 181 10 -8 53
-38 95 -68z m421 22 c3 -27 17 -102 30 -168 35 -179 38 -205 27 -205 -6 0 -30
21 -55 48 -148 158 -217 235 -217 242 0 12 192 140 201 135 3 -3 10 -26 14
-52z m-328 -409 c-3 -86 -8 -158 -10 -161 -6 -5 -96 60 -146 104 l-36 32 38
38 c20 21 37 44 37 51 0 13 96 92 112 92 8 0 9 -49 5 -156z m208 65 l89 -90
-30 -29 c-29 -29 -140 -100 -156 -100 -4 0 -8 70 -8 155 0 85 4 155 8 155 5 0
48 -41 97 -91z m-366 -240 c49 -34 106 -78 126 -99 35 -36 37 -41 26 -70 -6
-18 -39 -62 -74 -98 -35 -37 -90 -95 -122 -129 -32 -35 -63 -63 -69 -63 -23 0
-147 93 -142 106 3 8 22 89 42 181 33 157 38 170 70 201 18 17 38 32 44 32 6
0 50 -27 99 -61z m590 -11 c17 -35 35 -83 40 -108 5 -25 15 -65 22 -90 6 -25
13 -74 15 -110 l4 -65 -60 -37 c-34 -21 -65 -38 -70 -38 -5 0 -52 42 -104 94
-89 90 -180 209 -174 228 4 12 270 187 285 187 7 1 26 -27 42 -61z m-429 -483
c0 -95 -4 -155 -10 -155 -15 0 -111 60 -142 88 -16 14 -28 31 -28 38 0 30 139
183 168 184 9 0 12 -37 12 -155z m154 118 c128 -121 140 -142 104 -180 -24
-25 -132 -93 -149 -93 -5 0 -9 64 -9 155 0 85 3 155 7 155 3 0 25 -17 47 -37z
m398 -198 c10 -43 16 -81 13 -83 -6 -6 -104 98 -105 111 0 13 53 58 64 54 6
-2 19 -39 28 -82z m-928 56 c22 -24 14 -40 -50 -101 l-47 -45 7 50 c3 28 8 59
10 70 3 11 5 26 5 33 1 19 56 14 75 -7z m169 -128 c87 -61 167 -124 167 -132
0 -4 -44 -56 -97 -117 -54 -60 -111 -126 -128 -145 -16 -20 -49 -48 -71 -62
-41 -26 -42 -26 -59 -6 -10 10 -24 19 -32 19 -7 0 -36 17 -64 38 l-51 37 6 48
c8 70 46 131 151 243 52 54 95 102 95 107 0 16 33 5 83 -30z m637 -21 c141
-145 191 -222 205 -309 6 -39 5 -44 -17 -52 -14 -5 -57 -30 -97 -56 -40 -26
-77 -43 -81 -39 -4 5 -25 29 -45 54 -20 25 -73 83 -118 130 -45 47 -96 102
-114 122 l-32 36 22 19 c34 30 206 151 217 152 3 1 30 -25 60 -57z m-276 -279
c22 -21 63 -65 90 -98 27 -33 65 -76 83 -96 39 -41 37 -52 -17 -94 -22 -16
-51 -39 -65 -51 -39 -31 -133 -83 -139 -76 -8 7 -8 452 0 452 4 0 25 -17 48
-37z m-151 -25 c9 -106 2 -352 -11 -375 l-13 -23 -42 27 c-76 47 -188 134
-194 149 -8 21 85 136 169 210 40 34 76 63 80 63 3 1 8 -23 11 -51z m-475
-190 c17 -18 32 -37 32 -43 0 -5 -41 -53 -91 -105 l-90 -95 6 65 c3 36 11 100
17 144 l11 78 41 -6 c26 -5 53 -18 74 -38z m1144 15 c15 -38 48 -192 48 -227
0 -28 -1 -28 -24 -13 -47 31 -128 119 -160 174 -7 13 89 93 112 93 7 0 17 -12
24 -27z"/>
<path d="M3893 5287 c-24 -6 -43 -16 -43 -22 0 -20 69 -155 112 -217 51 -77
175 -200 195 -196 8 2 28 26 44 55 l30 52 -83 84 c-46 46 -108 122 -138 170
-30 47 -59 85 -65 85 -5 -1 -29 -6 -52 -11z"/>
<path d="M3660 5220 c-14 -4 -83 -24 -155 -44 -71 -20 -152 -42 -180 -50 -46
-13 -50 -16 -48 -42 3 -43 77 -209 137 -309 90 -151 263 -346 381 -430 56 -39
73 -43 82 -18 4 9 55 105 115 212 l109 196 -38 29 c-67 52 -161 153 -203 217
-42 65 -120 220 -120 239 0 12 -44 12 -80 0z"/>
<path d="M3045 5050 c-55 -15 -136 -37 -180 -49 -44 -12 -102 -28 -128 -35
-27 -8 -53 -18 -58 -23 -19 -19 73 -254 168 -432 39 -73 139 -232 172 -273 98
-124 256 -285 366 -375 64 -52 164 -118 169 -112 3 4 216 391 234 425 10 19 4
26 -63 75 -103 75 -278 256 -351 364 -72 105 -149 257 -189 370 -16 44 -31 83
-34 86 -3 4 -51 -6 -106 -21z"/>
<path d="M9515 4022 c-4 -21 -3 -38 4 -45 15 -15 14 -30 -3 -24 -9 4 -16 -8
-23 -39 -6 -24 -6 -44 -2 -44 5 0 9 12 10 28 0 15 3 21 6 15 7 -19 23 -16 23
5 0 10 5 23 11 29 7 7 6 25 -4 59 l-14 49 -8 -33z"/>
<path d="M3531 2006 c-39 -68 -86 -150 -106 -182 -19 -33 -35 -62 -35 -66 0
-8 64 -48 78 -48 6 0 25 26 43 58 48 85 46 84 81 66 29 -15 30 -17 24 -67 -4
-29 -9 -72 -12 -97 l-6 -45 46 -23 c26 -12 48 -22 50 -22 2 0 6 30 10 68 12
126 13 129 55 136 103 16 136 161 51 225 -26 19 -199 121 -205 121 -2 0 -35
-56 -74 -124z m182 -26 c49 -31 57 -52 37 -91 -18 -32 -74 -24 -139 19 -11 8
-8 18 14 56 15 25 30 46 34 46 4 0 28 -13 54 -30z"/>
<path d="M8395 2079 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M7035 2044 c-82 -30 -115 -75 -115 -156 l0 -60 37 7 c52 9 54 12 47
50 -5 28 -1 38 20 59 31 32 59 33 87 5 31 -31 20 -95 -36 -215 -45 -97 -46
-100 -31 -131 9 -18 22 -33 29 -33 13 0 254 125 271 141 7 6 -7 59 -20 78 -2
2 -41 -18 -88 -43 -47 -25 -86 -42 -86 -38 0 4 16 37 35 74 66 124 55 217 -30
258 -48 23 -65 24 -120 4z"/>
<path d="M2480 1900 c0 -5 3 -10 8 -10 4 0 34 -25 67 -55 33 -30 63 -55 68
-55 15 0 6 19 -19 39 l-27 21 24 1 c21 1 21 2 4 6 -11 3 -26 10 -34 17 -12 9
-13 8 -8 -7 4 -11 3 -16 -3 -12 -6 3 -10 11 -10 16 0 14 -41 48 -57 49 -7 0
-13 -4 -13 -10z"/>
<path d="M3948 1857 c-90 -68 -134 -217 -90 -303 36 -70 92 -104 173 -104 57
0 98 18 128 56 24 31 18 45 -29 64 -27 11 -33 10 -62 -15 -60 -51 -136 -9
-124 69 15 92 78 186 125 186 10 0 30 -10 45 -21 23 -18 27 -28 24 -62 -3 -39
-2 -40 42 -53 53 -16 60 -10 60 57 0 93 -77 159 -187 159 -54 0 -67 -4 -105
-33z"/>
<path d="M6685 1876 c-83 -23 -135 -84 -135 -158 0 -19 2 -37 4 -39 3 -3 23
-3 45 -1 39 4 40 5 43 45 6 74 92 104 120 41 16 -35 -8 -102 -74 -208 -48 -78
-49 -81 -37 -118 7 -21 17 -38 24 -38 6 0 67 23 135 51 69 28 133 53 143 56
20 6 20 15 4 60 l-12 31 -92 -38 c-51 -22 -93 -37 -93 -35 0 2 23 40 52 85 45
72 52 89 52 135 1 101 -79 159 -179 131z"/>
<path d="M8210 1853 c-14 -7 -18 -13 -10 -13 17 0 47 17 40 23 -3 2 -16 -2
-30 -10z"/>
<path d="M6300 1751 c-77 -24 -117 -62 -126 -119 -8 -49 15 -96 57 -117 l30
-16 -21 -26 c-28 -36 -27 -103 3 -131 26 -24 69 -42 101 -42 53 0 128 32 167
71 35 35 39 44 39 88 0 54 -28 95 -74 107 -31 7 -31 8 -9 44 13 23 15 37 7 67
-16 66 -93 98 -174 74z m72 -73 c26 -21 29 -57 7 -79 -11 -11 -28 -18 -37 -15
-9 2 -29 7 -44 10 -28 6 -47 44 -33 66 17 27 81 38 107 18z m75 -211 c38 -38
-11 -87 -86 -87 -33 0 -40 4 -51 30 -11 25 -10 34 5 56 15 24 22 26 67 22 27
-3 56 -12 65 -21z"/>
<path d="M4662 1663 c-6 -21 -8 -40 -4 -44 4 -3 49 -13 101 -22 52 -9 95 -18
97 -20 2 -2 -23 -31 -57 -65 -61 -63 -129 -184 -129 -229 0 -22 9 -27 71 -38
l35 -7 13 56 c18 80 64 165 127 236 46 52 55 67 52 94 -3 29 -6 31 -53 38 -27
3 -88 13 -135 22 -111 20 -105 22 -118 -21z"/>
<path d="M5945 1670 c-66 -10 -128 -19 -137 -19 -15 -1 -18 -10 -18 -47 l0
-45 58 6 c31 4 77 11 102 15 25 5 47 6 50 4 2 -3 -10 -31 -27 -62 -41 -74 -65
-169 -61 -240 l3 -57 36 3 c76 7 70 -2 73 118 4 109 20 171 63 245 18 30 10
100 -10 98 -7 -1 -66 -9 -132 -19z"/>
<path d="M4302 1641 c-7 -21 -12 -40 -12 -43 0 -3 18 -11 40 -18 47 -15 56
-10 65 41 6 34 5 36 -33 47 -22 7 -41 12 -44 12 -3 0 -10 -17 -16 -39z"/>
<path d="M5135 1631 c-32 -14 -63 -48 -87 -94 -30 -57 -32 -203 -4 -260 33
-66 101 -98 180 -83 107 20 172 214 116 344 -13 29 -36 62 -51 73 -32 23 -122
34 -154 20z m99 -77 c24 -23 29 -58 22 -140 -10 -127 -46 -176 -101 -140 -39
26 -44 121 -14 238 14 54 60 75 93 42z"/>
<path d="M5564 1619 c-10 -12 -45 -34 -76 -50 -56 -28 -58 -31 -58 -69 0 -22
3 -40 6 -40 4 0 30 13 59 30 28 16 55 27 58 23 4 -3 7 -60 7 -125 l0 -118 -65
0 -65 0 0 -42 0 -41 176 6 c97 3 179 8 181 11 3 3 2 21 -1 41 l-7 35 -59 0
-60 0 0 68 c0 37 -3 118 -7 180 l-6 112 -32 0 c-20 0 -39 -8 -51 -21z"/>
<path d="M2930 1581 c0 -10 30 -24 37 -17 3 3 2 9 -2 15 -8 13 -35 15 -35 2z"/>
<path d="M4236 1433 c-4 -16 -9 -35 -12 -44 -3 -11 7 -19 35 -27 54 -17 58
-16 64 16 3 15 9 35 12 44 5 13 -2 19 -32 27 -55 15 -60 14 -67 -16z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
